import { FORM_PAYER_TYPE } from '~/utils/dictionaries';

const STEPS = [
	{
		id: 0, name: 'login', title: 'Логин / регистрация', completed: true,
	},
	{
		id: 1, name: 'info', title: 'Основная информация', completed: true,
	},
	{
		id: 2, name: 'documents', title: 'Документы', completed: false,
	},
];

export function state() {
	return {
		isAuth: false,
		currentStep: 'login',
		redirectStep: null,
		isLoading: false,
		orderHash: '',
		currentProduct: {},
		price: {
			initial: 0,
			withVat: 0,
		},
		form: {
			payer: {
				type: FORM_PAYER_TYPE.PHYSICAL,
			},
			documents: {
				snils_document: {},
				passport_document: {},
				education_document: {},
			},
		},
		order: {
			data: {
				products: [],
			},
		},
	};
}

export const mutations = {
	setForm(state, stepData) {
		state.form = { ...state.form, ...stepData };
	},
	setStep(state, step) {
		state.currentStep = step;
		state.redirectStep = null;
	},
	setLoading(state, value) {
		state.isLoading = value;
	},
	setPayerType(state, value) {
		state.form.payer = { ...state.form.payer, type: value };
	},
	setAuth(state, value) {
		state.isAuth = value;
	},
	setPrice(state, payload) {
		state.price[payload.key] = payload.value;
	},
	setRedirectStep(state, value) {
		state.redirectStep = value;
	},
	setOrderHash(state, value) {
		state.orderHash = value;
	},
	setProduct(state, value) {
		state.currentProduct = value;
	},
};

export const getters = {
	baseForm: (state) => state.form,
	payerType: (state) => state.form.payer.type,
	isPhysicalPayer(state, getters) {
		return getters.payerType === FORM_PAYER_TYPE.PHYSICAL;
	},
	isIpPayer(state, getters) {
		return getters.payerType === FORM_PAYER_TYPE.IP;
	},
	isRepresentative(state) {
		return state.form?.is_representative;
	},
	isHideDocumentsStep(state, getters) {
		const { isRepresentative, isPhysicalPayer } = getters;
		return isRepresentative || !isPhysicalPayer;
	},
	isNoEducation(state) {
		const {
			no_education: noEducation,
			documents: { education_document: educationDocument },
		} = state.form;

		return Number((noEducation || educationDocument.no_education) || 0);
	},
	stepsList(state, getters) {
		const { isHideDocumentsStep, isPhysicalPayer, isRepresentative } = getters;
		const companyStep = {
			id: 2,
			name: 'company',
			title: 'Сведения о компании',
			completed: false,
		};
		const paymentStep = {
			id: isRepresentative && isPhysicalPayer ? 2 : 3,
			name: 'payment',
			title: (isPhysicalPayer ? 'Способ оплаты' : 'Оплатить по счету'),
			completed: false,
		};
		const currentSteps = isPhysicalPayer
			? [...STEPS, paymentStep]
			: [...STEPS, companyStep, paymentStep];

		const formattedSteps = currentSteps
			.filter((step) => (isHideDocumentsStep ? step.name !== 'documents' : true));
		return formattedSteps.map((step) => ({
			...step,
			// TODO: Добавить динамику
			completed: false,
			blocked: false,
		}));
	},
	totalPrice(state, getters) {
		const { initial, withVat } = state.price;
		const {
			price: currentProductPrice,
			program_price_with_vat: programPriceWithVat,
		} = state.currentProduct;
		const dynamicPrice = +currentProductPrice || +initial;
		const priceWithVat = +programPriceWithVat || +withVat;

		if (state.form.students?.length) {
			return state.form.students.reduce((acc, student) => {
				const price = +student.no_education ? priceWithVat : +dynamicPrice;

				return acc + price;
			}, 0);
		}
		if (getters.isNoEducation) return priceWithVat;
		return dynamicPrice;
	},
	currentRedirectStep(state) {
		return state.redirectStep;
	},
};

export const actions = {
};
