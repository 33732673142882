import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9bbf6454 = () => interopDefault(import('../pages/agreements/index.vue' /* webpackChunkName: "pages/agreements/index" */))
const _8b8f0bca = () => interopDefault(import('../pages/booking-payment/index.vue' /* webpackChunkName: "pages/booking-payment/index" */))
const _714253e6 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _f3a64528 = () => interopDefault(import('../pages/confirm-equis.vue' /* webpackChunkName: "pages/confirm-equis" */))
const _5605bafa = () => interopDefault(import('../pages/constructor/index.vue' /* webpackChunkName: "pages/constructor/index" */))
const _48fd90ac = () => interopDefault(import('../pages/convent-live.vue' /* webpackChunkName: "pages/convent-live" */))
const _8d3e8640 = () => interopDefault(import('../pages/corporate-user/index.vue' /* webpackChunkName: "pages/corporate-user/index" */))
const _78c7377e = () => interopDefault(import('../pages/formulate-fail/index.vue' /* webpackChunkName: "pages/formulate-fail/index" */))
const _6049bed6 = () => interopDefault(import('../pages/formulate-success/index.vue' /* webpackChunkName: "pages/formulate-success/index" */))
const _625ebeec = () => interopDefault(import('../pages/index-draft.vue' /* webpackChunkName: "pages/index-draft" */))
const _7e7e87f4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _2fd9b41f = () => interopDefault(import('../pages/navigator-v2/index.vue' /* webpackChunkName: "pages/navigator-v2/index" */))
const _ac1fefc0 = () => interopDefault(import('../pages/navigator/index.vue' /* webpackChunkName: "pages/navigator/index" */))
const _52ef17ca = () => interopDefault(import('../pages/ny2020-alumni.vue' /* webpackChunkName: "pages/ny2020-alumni" */))
const _359c4f35 = () => interopDefault(import('../pages/ny2024.vue' /* webpackChunkName: "pages/ny2024" */))
const _6a1f03ff = () => interopDefault(import('../pages/ok-success/index.vue' /* webpackChunkName: "pages/ok-success/index" */))
const _03e9848f = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _7ec73b80 = () => interopDefault(import('../pages/school-timeline.vue' /* webpackChunkName: "pages/school-timeline" */))
const _0f4058fa = () => interopDefault(import('../pages/tags.vue' /* webpackChunkName: "pages/tags" */))
const _8d9b5e5c = () => interopDefault(import('../pages/user-old/index.vue' /* webpackChunkName: "pages/user-old/index" */))
const _3f42a610 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _1a660ed0 = () => interopDefault(import('../pages/6268fS4fxR5H3Qtwj7vh/hiding-page-test/index.vue' /* webpackChunkName: "pages/6268fS4fxR5H3Qtwj7vh/hiding-page-test/index" */))
const _374830ee = () => interopDefault(import('../pages/alumni-platform/directory/index.vue' /* webpackChunkName: "pages/alumni-platform/directory/index" */))
const _8545746c = () => interopDefault(import('../pages/article/detail.amp.vue' /* webpackChunkName: "pages/article/detail.amp" */))
const _329b3d99 = () => interopDefault(import('../pages/booking-payment/ok-success.vue' /* webpackChunkName: "pages/booking-payment/ok-success" */))
const _948b2c96 = () => interopDefault(import('../pages/cart/cart.vue' /* webpackChunkName: "pages/cart/cart" */))
const _7e9c5d38 = () => interopDefault(import('../pages/cart/register.vue' /* webpackChunkName: "pages/cart/register" */))
const _dc10d39e = () => interopDefault(import('../pages/constructor/ok-success.vue' /* webpackChunkName: "pages/constructor/ok-success" */))
const _44c5d692 = () => interopDefault(import('../pages/corporate-user/profile.vue' /* webpackChunkName: "pages/corporate-user/profile" */))
const _b0637938 = () => interopDefault(import('../pages/corporate-user/profile/payment-success.vue' /* webpackChunkName: "pages/corporate-user/profile/payment-success" */))
const _d57930aa = () => interopDefault(import('../pages/corporate-user/profile/settings.vue' /* webpackChunkName: "pages/corporate-user/profile/settings" */))
const _ac2ea66e = () => interopDefault(import('../pages/corporate-user/profile/settings/account.vue' /* webpackChunkName: "pages/corporate-user/profile/settings/account" */))
const _55b029af = () => interopDefault(import('../pages/corporate-user/profile/settings/account/Avatar.vue' /* webpackChunkName: "pages/corporate-user/profile/settings/account/Avatar" */))
const _416b1a71 = () => interopDefault(import('../pages/corporate-user/profile/settings/account/Password.vue' /* webpackChunkName: "pages/corporate-user/profile/settings/account/Password" */))
const _28e453be = () => interopDefault(import('../pages/corporate-user/profile/settings/additionalInformation.vue' /* webpackChunkName: "pages/corporate-user/profile/settings/additionalInformation" */))
const _627041ee = () => interopDefault(import('../pages/corporate-user/profile/settings/index.vue' /* webpackChunkName: "pages/corporate-user/profile/settings/index" */))
const _54def5fa = () => interopDefault(import('../pages/corporate-user/profile/index.vue' /* webpackChunkName: "pages/corporate-user/profile/index" */))
const _07ddc02f = () => interopDefault(import('../pages/feedback/campus.vue' /* webpackChunkName: "pages/feedback/campus" */))
const _32558fe5 = () => interopDefault(import('../pages/feedback/ok-success/index.vue' /* webpackChunkName: "pages/feedback/ok-success/index" */))
const _31ed8953 = () => interopDefault(import('../pages/navigator/about-pogrammes.vue' /* webpackChunkName: "pages/navigator/about-pogrammes" */))
const _74855a72 = () => interopDefault(import('../pages/navigator/content.vue' /* webpackChunkName: "pages/navigator/content" */))
const _b43f9f8a = () => interopDefault(import('../pages/navigator/events.vue' /* webpackChunkName: "pages/navigator/events" */))
const _3a2ca1b0 = () => interopDefault(import('../pages/navigator/links.js' /* webpackChunkName: "pages/navigator/links" */))
const _59a67632 = () => interopDefault(import('../pages/navigator/media.vue' /* webpackChunkName: "pages/navigator/media" */))
const _4a45f5f0 = () => interopDefault(import('../pages/navigator/meta.js' /* webpackChunkName: "pages/navigator/meta" */))
const _2a8c5940 = () => interopDefault(import('../pages/navigator/professors-old.vue' /* webpackChunkName: "pages/navigator/professors-old" */))
const _5f933ef4 = () => interopDefault(import('../pages/navigator/programmes-with-price.vue' /* webpackChunkName: "pages/navigator/programmes-with-price" */))
const _519f180e = () => interopDefault(import('../pages/navigator/programmes.vue' /* webpackChunkName: "pages/navigator/programmes" */))
const _385459b2 = () => interopDefault(import('../pages/navigator/vacancies.vue' /* webpackChunkName: "pages/navigator/vacancies" */))
const _31c359dd = () => interopDefault(import('../pages/order/ok-success.vue' /* webpackChunkName: "pages/order/ok-success" */))
const _1d9b971a = () => interopDefault(import('../pages/user-old/autologin.vue' /* webpackChunkName: "pages/user-old/autologin" */))
const _3bce0262 = () => interopDefault(import('../pages/user-old/profile-v1.vue' /* webpackChunkName: "pages/user-old/profile-v1" */))
const _1ff55593 = () => interopDefault(import('../pages/user-old/profile-v1/applications.vue' /* webpackChunkName: "pages/user-old/profile-v1/applications" */))
const _514ebbf8 = () => interopDefault(import('../pages/user-old/profile-v1/courses.vue' /* webpackChunkName: "pages/user-old/profile-v1/courses" */))
const _fe2cbe10 = () => interopDefault(import('../pages/user-old/profile-v1/documents.vue' /* webpackChunkName: "pages/user-old/profile-v1/documents" */))
const _9537aa0c = () => interopDefault(import('../pages/user-old/profile-v1/edit.vue' /* webpackChunkName: "pages/user-old/profile-v1/edit" */))
const _8d9ab384 = () => interopDefault(import('../pages/user-old/profile-v1/edit/avatar.vue' /* webpackChunkName: "pages/user-old/profile-v1/edit/avatar" */))
const _637ea180 = () => interopDefault(import('../pages/user-old/profile-v1/edit/password.vue' /* webpackChunkName: "pages/user-old/profile-v1/edit/password" */))
const _c681e406 = () => interopDefault(import('../pages/user-old/profile-v1/edit/index.vue' /* webpackChunkName: "pages/user-old/profile-v1/edit/index" */))
const _30df837b = () => interopDefault(import('../pages/user-old/profile-v1/library.vue' /* webpackChunkName: "pages/user-old/profile-v1/library" */))
const _33047756 = () => interopDefault(import('../pages/user-old/profile-v1/orders.vue' /* webpackChunkName: "pages/user-old/profile-v1/orders" */))
const _23aed3e1 = () => interopDefault(import('../pages/user-old/profile-v1/tests.vue' /* webpackChunkName: "pages/user-old/profile-v1/tests" */))
const _38d8ee12 = () => interopDefault(import('../pages/user-old/profile-v1/index.vue' /* webpackChunkName: "pages/user-old/profile-v1/index" */))
const _2f597429 = () => interopDefault(import('../pages/user-old/profile.vue' /* webpackChunkName: "pages/user-old/profile" */))
const _cc74da40 = () => interopDefault(import('../pages/user-old/profile/admissions.vue' /* webpackChunkName: "pages/user-old/profile/admissions" */))
const _1fd3e279 = () => interopDefault(import('../pages/user-old/profile/applications.vue' /* webpackChunkName: "pages/user-old/profile/applications" */))
const _3f8e7602 = () => interopDefault(import('../pages/user-old/profile/corporate.vue' /* webpackChunkName: "pages/user-old/profile/corporate" */))
const _754d915c = () => interopDefault(import('../pages/user-old/profile/courses.vue' /* webpackChunkName: "pages/user-old/profile/courses" */))
const _b62c0256 = () => interopDefault(import('../pages/user-old/profile/library.vue' /* webpackChunkName: "pages/user-old/profile/library" */))
const _c02d148a = () => interopDefault(import('../pages/user-old/profile/orders.vue' /* webpackChunkName: "pages/user-old/profile/orders" */))
const _12dd0254 = () => interopDefault(import('../pages/user-old/profile/payment-success.vue' /* webpackChunkName: "pages/user-old/profile/payment-success" */))
const _e8bb8cfa = () => interopDefault(import('../pages/user-old/profile/progress.vue' /* webpackChunkName: "pages/user-old/profile/progress" */))
const _fe936f0e = () => interopDefault(import('../pages/user-old/profile/settings.vue' /* webpackChunkName: "pages/user-old/profile/settings" */))
const _338cdf97 = () => interopDefault(import('../pages/user-old/profile/settings/account.vue' /* webpackChunkName: "pages/user-old/profile/settings/account" */))
const _04fc59be = () => interopDefault(import('../pages/user-old/profile/settings/account/Avatar.vue' /* webpackChunkName: "pages/user-old/profile/settings/account/Avatar" */))
const _3b782f63 = () => interopDefault(import('../pages/user-old/profile/settings/account/Password.vue' /* webpackChunkName: "pages/user-old/profile/settings/account/Password" */))
const _20fbef22 = () => interopDefault(import('../pages/user-old/profile/settings/additionalInformation.vue' /* webpackChunkName: "pages/user-old/profile/settings/additionalInformation" */))
const _cbfc69a2 = () => interopDefault(import('../pages/user-old/profile/settings/agreements.vue' /* webpackChunkName: "pages/user-old/profile/settings/agreements" */))
const _f1ef37b8 = () => interopDefault(import('../pages/user-old/profile/settings/documentation.vue' /* webpackChunkName: "pages/user-old/profile/settings/documentation" */))
const _d74b80b2 = () => interopDefault(import('../pages/user-old/profile/settings/documentation/index.vue' /* webpackChunkName: "pages/user-old/profile/settings/documentation/index" */))
const _757ed03c = () => interopDefault(import('../pages/user-old/profile/settings/index.vue' /* webpackChunkName: "pages/user-old/profile/settings/index" */))
const _b7f936c4 = () => interopDefault(import('../pages/user-old/profile/loyalty/about.vue' /* webpackChunkName: "pages/user-old/profile/loyalty/about" */))
const _7581f4e2 = () => interopDefault(import('../pages/user-old/profile/loyalty/card.vue' /* webpackChunkName: "pages/user-old/profile/loyalty/card" */))
const _e7b86c32 = () => interopDefault(import('../pages/user-old/profile/loyalty/faq.vue' /* webpackChunkName: "pages/user-old/profile/loyalty/faq" */))
const _0f4638a2 = () => interopDefault(import('../pages/user-old/profile/loyalty/gift.vue' /* webpackChunkName: "pages/user-old/profile/loyalty/gift" */))
const _74762036 = () => interopDefault(import('../pages/user-old/profile/loyalty/history.vue' /* webpackChunkName: "pages/user-old/profile/loyalty/history" */))
const _063a601a = () => interopDefault(import('../pages/user-old/profile/loyalty/report.vue' /* webpackChunkName: "pages/user-old/profile/loyalty/report" */))
const _681e77ec = () => interopDefault(import('../pages/user-old/profile/index.vue' /* webpackChunkName: "pages/user-old/profile/index" */))
const _3d5d1f80 = () => interopDefault(import('../pages/user/autologin.vue' /* webpackChunkName: "pages/user/autologin" */))
const _24056613 = () => interopDefault(import('../pages/user/directory.vue' /* webpackChunkName: "pages/user/directory" */))
const _d58b0d2a = () => interopDefault(import('../pages/user/profile-v3.vue' /* webpackChunkName: "pages/user/profile-v3" */))
const _3d67df44 = () => interopDefault(import('../pages/user/profile-v3/admissions.vue' /* webpackChunkName: "pages/user/profile-v3/admissions" */))
const _4072a66a = () => interopDefault(import('../pages/user/profile-v3/alumni-space.vue' /* webpackChunkName: "pages/user/profile-v3/alumni-space" */))
const _4a09da94 = () => interopDefault(import('../pages/user/profile-v3/courses.vue' /* webpackChunkName: "pages/user/profile-v3/courses" */))
const _a3c45b20 = () => interopDefault(import('../pages/user/profile-v3/dashboard.vue' /* webpackChunkName: "pages/user/profile-v3/dashboard" */))
const _dcd89320 = () => interopDefault(import('../pages/user/profile-v3/favorite.vue' /* webpackChunkName: "pages/user/profile-v3/favorite" */))
const _3e987216 = () => interopDefault(import('../pages/user/profile-v3/index_old.vue' /* webpackChunkName: "pages/user/profile-v3/index_old" */))
const _3b225aba = () => interopDefault(import('../pages/user/profile-v3/invoices.vue' /* webpackChunkName: "pages/user/profile-v3/invoices" */))
const _3f80e925 = () => interopDefault(import('../pages/user/profile-v3/loyalty/index.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/index" */))
const _190d1c80 = () => interopDefault(import('../pages/user/profile-v3/media.vue' /* webpackChunkName: "pages/user/profile-v3/media" */))
const _83d6cc08 = () => interopDefault(import('../pages/user/profile-v3/orders/index.vue' /* webpackChunkName: "pages/user/profile-v3/orders/index" */))
const _d0d87fd0 = () => interopDefault(import('../pages/user/profile-v3/payment-success.vue' /* webpackChunkName: "pages/user/profile-v3/payment-success" */))
const _c3b4d3c6 = () => interopDefault(import('../pages/user/profile-v3/programs-old.vue' /* webpackChunkName: "pages/user/profile-v3/programs-old" */))
const _47a9e606 = () => interopDefault(import('../pages/user/profile-v3/programs/index.vue' /* webpackChunkName: "pages/user/profile-v3/programs/index" */))
const _31097c94 = () => interopDefault(import('../pages/user/profile-v3/registrations.vue' /* webpackChunkName: "pages/user/profile-v3/registrations" */))
const _1fc90e60 = () => interopDefault(import('../pages/user/profile-v3/loyalty/about.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/about" */))
const _6fa6f726 = () => interopDefault(import('../pages/user/profile-v3/loyalty/badges.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/badges" */))
const _451c1b8d = () => interopDefault(import('../pages/user/profile-v3/loyalty/card.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/card" */))
const _5f9257d4 = () => interopDefault(import('../pages/user/profile-v3/loyalty/donate-points.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/donate-points" */))
const _356cfc29 = () => interopDefault(import('../pages/user/profile-v3/loyalty/faq.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/faq" */))
const _7839f9ad = () => interopDefault(import('../pages/user/profile-v3/loyalty/gift.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/gift" */))
const _66c73127 = () => interopDefault(import('../pages/user/profile-v3/loyalty/history.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/history" */))
const _16375cba = () => interopDefault(import('../pages/user/profile-v3/loyalty/loyalty-history/index.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/loyalty-history/index" */))
const _511e5a57 = () => interopDefault(import('../pages/user/profile-v3/loyalty/partners-privileges.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/partners-privileges" */))
const _0db6079f = () => interopDefault(import('../pages/user/profile-v3/loyalty/privileges.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/privileges" */))
const _f49636a4 = () => interopDefault(import('../pages/user/profile-v3/loyalty/referral-promo-code.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/referral-promo-code" */))
const _0c5b431e = () => interopDefault(import('../pages/user/profile-v3/loyalty/report.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/report" */))
const _c1c890fe = () => interopDefault(import('../pages/user/profile-v3/loyalty/special-promotions.vue' /* webpackChunkName: "pages/user/profile-v3/loyalty/special-promotions" */))
const _43c303ae = () => interopDefault(import('../pages/user/profile-v3/index.vue' /* webpackChunkName: "pages/user/profile-v3/index" */))
const _4e8e2938 = () => interopDefault(import('../pages/user/profile-v3/orders/_id.vue' /* webpackChunkName: "pages/user/profile-v3/orders/_id" */))
const _22a92604 = () => interopDefault(import('../pages/user/profile-v3/programs/_program/index.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/index" */))
const _4d8f4b2c = () => interopDefault(import('../pages/user/profile-v3/programs/_program/about-info.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/about-info" */))
const _02f14b3f = () => interopDefault(import('../pages/user/profile-v3/programs/_program/about.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/about" */))
const _3b4c05be = () => interopDefault(import('../pages/user/profile-v3/programs/_program/admission-application.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/admission-application" */))
const _0f645b32 = () => interopDefault(import('../pages/user/profile-v3/programs/_program/agreements.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/agreements" */))
const _1cef5086 = () => interopDefault(import('../pages/user/profile-v3/programs/_program/class-profile.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/class-profile" */))
const _7fed298a = () => interopDefault(import('../pages/user/profile-v3/programs/_program/class-schedule.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/class-schedule" */))
const _cbe69b5a = () => interopDefault(import('../pages/user/profile-v3/programs/_program/contracts.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/contracts" */))
const _7cb9ea05 = () => interopDefault(import('../pages/user/profile-v3/programs/_program/education-documents.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/education-documents" */))
const _d47c6db2 = () => interopDefault(import('../pages/user/profile-v3/programs/_program/elective.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/elective" */))
const _5a9dd448 = () => interopDefault(import('../pages/user/profile-v3/programs/_program/organizational.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/organizational" */))
const _93098056 = () => interopDefault(import('../pages/user/profile-v3/programs/_program/schedule.vue' /* webpackChunkName: "pages/user/profile-v3/programs/_program/schedule" */))
const _3cdf5ecf = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _950ab70c = () => interopDefault(import('../pages/user/profile/admissions.vue' /* webpackChunkName: "pages/user/profile/admissions" */))
const _62a0a9e7 = () => interopDefault(import('../pages/user/profile/alumni-space.vue' /* webpackChunkName: "pages/user/profile/alumni-space" */))
const _2fb51bf8 = () => interopDefault(import('../pages/user/profile/courses.vue' /* webpackChunkName: "pages/user/profile/courses" */))
const _6150a18c = () => interopDefault(import('../pages/user/profile/favorite.vue' /* webpackChunkName: "pages/user/profile/favorite" */))
const _6678ea7a = () => interopDefault(import('../pages/user/profile/index_old.vue' /* webpackChunkName: "pages/user/profile/index_old" */))
const _0adf45d6 = () => interopDefault(import('../pages/user/profile/invoices.vue' /* webpackChunkName: "pages/user/profile/invoices" */))
const _4944514e = () => interopDefault(import('../pages/user/profile/loyalty-b2b/index.vue' /* webpackChunkName: "pages/user/profile/loyalty-b2b/index" */))
const _17e68f89 = () => interopDefault(import('../pages/user/profile/loyalty/index.vue' /* webpackChunkName: "pages/user/profile/loyalty/index" */))
const _25e487b8 = () => interopDefault(import('../pages/user/profile/media.vue' /* webpackChunkName: "pages/user/profile/media" */))
const _40ee9718 = () => interopDefault(import('../pages/user/profile/orders/index.vue' /* webpackChunkName: "pages/user/profile/orders/index" */))
const _25ad4508 = () => interopDefault(import('../pages/user/profile/payment-success.vue' /* webpackChunkName: "pages/user/profile/payment-success" */))
const _20ff9339 = () => interopDefault(import('../pages/user/profile/programs-old.vue' /* webpackChunkName: "pages/user/profile/programs-old" */))
const _7bf90c22 = () => interopDefault(import('../pages/user/profile/programs/index.vue' /* webpackChunkName: "pages/user/profile/programs/index" */))
const _803e2fcc = () => interopDefault(import('../pages/user/profile/registrations.vue' /* webpackChunkName: "pages/user/profile/registrations" */))
const _298c7689 = () => interopDefault(import('../pages/user/profile/loyalty-b2b/about.vue' /* webpackChunkName: "pages/user/profile/loyalty-b2b/about" */))
const _2ca68a44 = () => interopDefault(import('../pages/user/profile/loyalty-b2b/card.vue' /* webpackChunkName: "pages/user/profile/loyalty-b2b/card" */))
const _079356a9 = () => interopDefault(import('../pages/user/profile/loyalty-b2b/earn-and-spent-points.vue' /* webpackChunkName: "pages/user/profile/loyalty-b2b/earn-and-spent-points" */))
const _3be34adc = () => interopDefault(import('../pages/user/profile/loyalty-b2b/faq.vue' /* webpackChunkName: "pages/user/profile/loyalty-b2b/faq" */))
const _0d513310 = () => interopDefault(import('../pages/user/profile/loyalty-b2b/history.vue' /* webpackChunkName: "pages/user/profile/loyalty-b2b/history" */))
const _060c47cc = () => interopDefault(import('../pages/user/profile/loyalty-b2b/loyalty-history/index.vue' /* webpackChunkName: "pages/user/profile/loyalty-b2b/loyalty-history/index" */))
const _287bfb68 = () => interopDefault(import('../pages/user/profile/loyalty-b2b/report.vue' /* webpackChunkName: "pages/user/profile/loyalty-b2b/report" */))
const _0fa29678 = () => interopDefault(import('../pages/user/profile/loyalty/about.vue' /* webpackChunkName: "pages/user/profile/loyalty/about" */))
const _0708aaee = () => interopDefault(import('../pages/user/profile/loyalty/badges.vue' /* webpackChunkName: "pages/user/profile/loyalty/badges" */))
const _7013ceae = () => interopDefault(import('../pages/user/profile/loyalty/card.vue' /* webpackChunkName: "pages/user/profile/loyalty/card" */))
const _7e300d70 = () => interopDefault(import('../pages/user/profile/loyalty/create-group-donation.vue' /* webpackChunkName: "pages/user/profile/loyalty/create-group-donation" */))
const _4dad5a38 = () => interopDefault(import('../pages/user/profile/loyalty/donate-points.vue' /* webpackChunkName: "pages/user/profile/loyalty/donate-points" */))
const _31de28e6 = () => interopDefault(import('../pages/user/profile/loyalty/faq.vue' /* webpackChunkName: "pages/user/profile/loyalty/faq" */))
const _09d8126e = () => interopDefault(import('../pages/user/profile/loyalty/gift.vue' /* webpackChunkName: "pages/user/profile/loyalty/gift" */))
const _9aeb7988 = () => interopDefault(import('../pages/user/profile/loyalty/group-donation/index.vue' /* webpackChunkName: "pages/user/profile/loyalty/group-donation/index" */))
const _874662ea = () => interopDefault(import('../pages/user/profile/loyalty/history.vue' /* webpackChunkName: "pages/user/profile/loyalty/history" */))
const _0b98b007 = () => interopDefault(import('../pages/user/profile/loyalty/loyalty-history/index.vue' /* webpackChunkName: "pages/user/profile/loyalty/loyalty-history/index" */))
const _095a8e0b = () => interopDefault(import('../pages/user/profile/loyalty/my-group-donations.vue' /* webpackChunkName: "pages/user/profile/loyalty/my-group-donations" */))
const _755f01bb = () => interopDefault(import('../pages/user/profile/loyalty/partners-privileges.vue' /* webpackChunkName: "pages/user/profile/loyalty/partners-privileges" */))
const _c8e6808a = () => interopDefault(import('../pages/user/profile/loyalty/privileges.vue' /* webpackChunkName: "pages/user/profile/loyalty/privileges" */))
const _ac14e7dc = () => interopDefault(import('../pages/user/profile/loyalty/referral-promo-code.vue' /* webpackChunkName: "pages/user/profile/loyalty/referral-promo-code" */))
const _2e21848d = () => interopDefault(import('../pages/user/profile/loyalty/report.vue' /* webpackChunkName: "pages/user/profile/loyalty/report" */))
const _16bb3060 = () => interopDefault(import('../pages/user/profile/loyalty/special-promotions/index.vue' /* webpackChunkName: "pages/user/profile/loyalty/special-promotions/index" */))
const _3d574e12 = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _97561794 = () => interopDefault(import('../pages/user/profile/loyalty/group-donation/_code.vue' /* webpackChunkName: "pages/user/profile/loyalty/group-donation/_code" */))
const _0619cac8 = () => interopDefault(import('../pages/user/profile/loyalty/special-promotions/_id.vue' /* webpackChunkName: "pages/user/profile/loyalty/special-promotions/_id" */))
const _a6310100 = () => interopDefault(import('../pages/user/profile/orders/_id.vue' /* webpackChunkName: "pages/user/profile/orders/_id" */))
const _13e5c130 = () => interopDefault(import('../pages/user/profile/programs/_program/index.vue' /* webpackChunkName: "pages/user/profile/programs/_program/index" */))
const _9d38df70 = () => interopDefault(import('../pages/user/profile/programs/_program/about-info.vue' /* webpackChunkName: "pages/user/profile/programs/_program/about-info" */))
const _535576ba = () => interopDefault(import('../pages/user/profile/programs/_program/about.vue' /* webpackChunkName: "pages/user/profile/programs/_program/about" */))
const _6cd61e45 = () => interopDefault(import('../pages/user/profile/programs/_program/academic-plan.vue' /* webpackChunkName: "pages/user/profile/programs/_program/academic-plan" */))
const _b80e91bc = () => interopDefault(import('../pages/user/profile/programs/_program/admission-application.vue' /* webpackChunkName: "pages/user/profile/programs/_program/admission-application" */))
const _5c221783 = () => interopDefault(import('../pages/user/profile/programs/_program/agreements.vue' /* webpackChunkName: "pages/user/profile/programs/_program/agreements" */))
const _5805a5ea = () => interopDefault(import('../pages/user/profile/programs/_program/class-profile.vue' /* webpackChunkName: "pages/user/profile/programs/_program/class-profile" */))
const _b0bcfeb4 = () => interopDefault(import('../pages/user/profile/programs/_program/class-schedule.vue' /* webpackChunkName: "pages/user/profile/programs/_program/class-schedule" */))
const _83654c92 = () => interopDefault(import('../pages/user/profile/programs/_program/contracts.vue' /* webpackChunkName: "pages/user/profile/programs/_program/contracts" */))
const _3b59374e = () => interopDefault(import('../pages/user/profile/programs/_program/documents.vue' /* webpackChunkName: "pages/user/profile/programs/_program/documents" */))
const _aa28b72e = () => interopDefault(import('../pages/user/profile/programs/_program/education-documents.vue' /* webpackChunkName: "pages/user/profile/programs/_program/education-documents" */))
const _3d06a4b0 = () => interopDefault(import('../pages/user/profile/programs/_program/elective-bachelor.vue' /* webpackChunkName: "pages/user/profile/programs/_program/elective-bachelor" */))
const _54dca543 = () => interopDefault(import('../pages/user/profile/programs/_program/elective.vue' /* webpackChunkName: "pages/user/profile/programs/_program/elective" */))
const _aa691a54 = () => interopDefault(import('../pages/user/profile/programs/_program/grades.vue' /* webpackChunkName: "pages/user/profile/programs/_program/grades" */))
const _0b352610 = () => interopDefault(import('../pages/user/profile/programs/_program/organizational.vue' /* webpackChunkName: "pages/user/profile/programs/_program/organizational" */))
const _13bd5d87 = () => interopDefault(import('../pages/user/profile/programs/_program/payments.vue' /* webpackChunkName: "pages/user/profile/programs/_program/payments" */))
const _75961bf1 = () => interopDefault(import('../pages/user/profile/programs/_program/schedule.vue' /* webpackChunkName: "pages/user/profile/programs/_program/schedule" */))
const _de07d184 = () => interopDefault(import('../pages/user/profile/programs/_program/statements.vue' /* webpackChunkName: "pages/user/profile/programs/_program/statements" */))
const _2ba7c659 = () => interopDefault(import('../pages/alumni-platform/directory/welcome.vue' /* webpackChunkName: "pages/alumni-platform/directory/welcome" */))
const _994ab426 = () => interopDefault(import('../pages/cart/cart_content/emptyCart.vue' /* webpackChunkName: "pages/cart/cart_content/emptyCart" */))
const _5d7fd09f = () => interopDefault(import('../pages/cart/cart_content/fullCart.vue' /* webpackChunkName: "pages/cart/cart_content/fullCart" */))
const _69477b68 = () => interopDefault(import('../pages/sp/dev/course_blocks.vue' /* webpackChunkName: "pages/sp/dev/course_blocks" */))
const _2745bcb6 = () => interopDefault(import('../pages/sp/dev/lk_learn.vue' /* webpackChunkName: "pages/sp/dev/lk_learn" */))
const _746499a4 = () => interopDefault(import('../pages/sp/dev/tst1.vue' /* webpackChunkName: "pages/sp/dev/tst1" */))
const _4874ab58 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8e7f60de = () => interopDefault(import('../pages/module-tests-v2/add/_stream.vue' /* webpackChunkName: "pages/module-tests-v2/add/_stream" */))
const _92b8dbc2 = () => interopDefault(import('../pages/activate/_token/index.vue' /* webpackChunkName: "pages/activate/_token/index" */))
const _5d8b4ec8 = () => interopDefault(import('../pages/donations/_hash/index.vue' /* webpackChunkName: "pages/donations/_hash/index" */))
const _aa94aa88 = () => interopDefault(import('../pages/event-qr/_hash.vue' /* webpackChunkName: "pages/event-qr/_hash" */))
const _721d4997 = () => interopDefault(import('../pages/formulate-success/_scenario_id.vue' /* webpackChunkName: "pages/formulate-success/_scenario_id" */))
const _333d68cc = () => interopDefault(import('../pages/module-tests-v2/_id.vue' /* webpackChunkName: "pages/module-tests-v2/_id" */))
const _720cf8fa = () => interopDefault(import('../pages/module-tests/_stream.vue' /* webpackChunkName: "pages/module-tests/_stream" */))
const _dcd5e21c = () => interopDefault(import('../pages/order/_hash.vue' /* webpackChunkName: "pages/order/_hash" */))
const _8ab2b30c = () => interopDefault(import('../pages/stream/_stream.vue' /* webpackChunkName: "pages/stream/_stream" */))
const _5c03289b = () => interopDefault(import('../pages/donations/_hash/qr.vue' /* webpackChunkName: "pages/donations/_hash/qr" */))
const _07e08397 = () => interopDefault(import('../pages/donations/_hash/result.vue' /* webpackChunkName: "pages/donations/_hash/result" */))
const _0228a312 = () => interopDefault(import('../pages/invoices/_hash/qr.vue' /* webpackChunkName: "pages/invoices/_hash/qr" */))
const _79f5581e = () => interopDefault(import('../project/success.vue' /* webpackChunkName: "" */))
const _d4ef9674 = () => interopDefault(import('../project/anti-fragility/index.vue' /* webpackChunkName: "" */))
const _70c88eb0 = () => interopDefault(import('../project/anti-fragility/content/index.vue' /* webpackChunkName: "" */))
const _c67f7322 = () => interopDefault(import('../project/professors/section.vue' /* webpackChunkName: "" */))
const _890245b0 = () => interopDefault(import('../project/secret-santa/index.vue' /* webpackChunkName: "" */))
const _4882e38f = () => interopDefault(import('../project/programmes/index.vue' /* webpackChunkName: "" */))
const _58d6fc33 = () => interopDefault(import('../project/professors/cv-old.vue' /* webpackChunkName: "" */))
const _5ac374b7 = () => interopDefault(import('../project/professors/detail.vue' /* webpackChunkName: "" */))
const _500c4b18 = () => interopDefault(import('../project/management/cv-old.vue' /* webpackChunkName: "" */))
const _4c335a10 = () => interopDefault(import('../project/management/detail.vue' /* webpackChunkName: "" */))
const _5efc9b60 = () => interopDefault(import('../project/partners/detail.vue' /* webpackChunkName: "" */))
const _17c7e932 = () => interopDefault(import('../project/article/section.vue' /* webpackChunkName: "" */))
const _4bc8e9b7 = () => interopDefault(import('../project/article/partner-event-detail.vue' /* webpackChunkName: "" */))
const _488b2a98 = () => interopDefault(import('../project/article/partner-material-detail.vue' /* webpackChunkName: "" */))
const _2e55dc78 = () => interopDefault(import('../project/degree/index.vue' /* webpackChunkName: "" */))
const _d062baec = () => interopDefault(import('../project/ips/index.vue' /* webpackChunkName: "" */))
const _6d6fed7d = () => interopDefault(import('../project/hddm/index.vue' /* webpackChunkName: "" */))
const _5889540b = () => interopDefault(import('../project/hddm/inner-page.vue' /* webpackChunkName: "" */))
const _76d4f741 = () => interopDefault(import('../project/tinkoff-credit/index.vue' /* webpackChunkName: "" */))
const _c0001554 = () => interopDefault(import('../project/inTrend/index.vue' /* webpackChunkName: "" */))
const _b79d685c = () => interopDefault(import('../project/inTrend/inner-page.vue' /* webpackChunkName: "" */))
const _a8437290 = () => interopDefault(import('../project/event/index.vue' /* webpackChunkName: "" */))
const _524db06c = () => interopDefault(import('../project/donation/index.vue' /* webpackChunkName: "" */))
const _c6c23dee = () => interopDefault(import('../project/donation/detail.vue' /* webpackChunkName: "" */))
const _310b3760 = () => interopDefault(import('../project/research/detail.vue' /* webpackChunkName: "" */))
const _7fd752ee = () => interopDefault(import('../project/longread/detail.vue' /* webpackChunkName: "" */))
const _2876667b = () => interopDefault(import('../project/donation/success.vue' /* webpackChunkName: "" */))
const _46080245 = () => interopDefault(import('../project/crec/index.vue' /* webpackChunkName: "" */))
const _36d4987c = () => interopDefault(import('../project/crec/governance/index.vue' /* webpackChunkName: "" */))
const _d5b2077a = () => interopDefault(import('../project/crec/inner-page.vue' /* webpackChunkName: "" */))
const _15267e28 = () => interopDefault(import('../project/about-school/index.vue' /* webpackChunkName: "" */))
const _4aee7580 = () => interopDefault(import('../project/about-school/sustainability/index.vue' /* webpackChunkName: "" */))
const _3a024dfc = () => interopDefault(import('../project/about-school/inner-page.vue' /* webpackChunkName: "" */))
const _1b4d8883 = () => interopDefault(import('../project/artifact-space/index.vue' /* webpackChunkName: "" */))
const _5631acc5 = () => interopDefault(import('../project/artifact-space/inner-page.vue' /* webpackChunkName: "" */))
const _b4520d5e = () => interopDefault(import('../project/convent2020/index.vue' /* webpackChunkName: "" */))
const _77948cb7 = () => interopDefault(import('../project/convent2020/inner-page.vue' /* webpackChunkName: "" */))
const _29f23d7a = () => interopDefault(import('../project/corporate/index.vue' /* webpackChunkName: "" */))
const _66da27b2 = () => interopDefault(import('../project/corporate/inner-page-china-unit.vue' /* webpackChunkName: "" */))
const _379c67f6 = () => interopDefault(import('../project/corporate/inner-page.vue' /* webpackChunkName: "" */))
const _10c1679d = () => interopDefault(import('../project/corporate-old/index.vue' /* webpackChunkName: "" */))
const _f4a1a42a = () => interopDefault(import('../project/corporate-old/inner-page.vue' /* webpackChunkName: "" */))
const _3a7b06f5 = () => interopDefault(import('../project/b2g/index.vue' /* webpackChunkName: "" */))
const _704b6593 = () => interopDefault(import('../project/b2g/inner-page.vue' /* webpackChunkName: "" */))
const _21b3d603 = () => interopDefault(import('../project/internships/index.vue' /* webpackChunkName: "" */))
const _c1c06176 = () => interopDefault(import('../project/internships/inner-page.vue' /* webpackChunkName: "" */))
const _9f358234 = () => interopDefault(import('../project/personal-development/index.vue' /* webpackChunkName: "" */))
const _db29077c = () => interopDefault(import('../project/personal-development/inner-page.vue' /* webpackChunkName: "" */))
const _4415704b = () => interopDefault(import('../project/trend-award/index.vue' /* webpackChunkName: "" */))
const _c7c0b014 = () => interopDefault(import('../project/digital/index.vue' /* webpackChunkName: "" */))
const _33183d32 = () => interopDefault(import('../project/digital/inner-page.vue' /* webpackChunkName: "" */))
const _6977d166 = () => interopDefault(import('../project/privacy/index.vue' /* webpackChunkName: "" */))
const _9adf0c7c = () => interopDefault(import('../project/privacy/inner-page.vue' /* webpackChunkName: "" */))
const _6e260919 = () => interopDefault(import('../project/probono/index.vue' /* webpackChunkName: "" */))
const _76a019ef = () => interopDefault(import('../project/probono/inner-page.vue' /* webpackChunkName: "" */))
const _f480644c = () => interopDefault(import('../project/msb/index.vue' /* webpackChunkName: "" */))
const _144fb464 = () => interopDefault(import('../project/msb/inner-page.vue' /* webpackChunkName: "" */))
const _90ef0ce2 = () => interopDefault(import('../project/rabota-v-shkole-upravleniya-skolkovo/index.vue' /* webpackChunkName: "" */))
const _bac0f98e = () => interopDefault(import('../project/rabota-v-shkole-upravleniya-skolkovo/inner-page.vue' /* webpackChunkName: "" */))
const _4f61456d = () => interopDefault(import('../project/healthcare-programs/index.vue' /* webpackChunkName: "" */))
const _1461721b = () => interopDefault(import('../project/healthcare-programs/inner-page.vue' /* webpackChunkName: "" */))
const _6c7c3434 = () => interopDefault(import('../project/bachelor-programmes/index.vue' /* webpackChunkName: "" */))
const _e700b098 = () => interopDefault(import('../project/bachelor-programmes/inner-page.vue' /* webpackChunkName: "" */))
const _80eb8cd2 = () => interopDefault(import('../project/bachelors-programmes/index.vue' /* webpackChunkName: "" */))
const _235c8831 = () => interopDefault(import('../project/bachelors-programmes/inner-page.vue' /* webpackChunkName: "" */))
const _303b36d5 = () => interopDefault(import('../project/young-professionals/index.vue' /* webpackChunkName: "" */))
const _76e3bc9a = () => interopDefault(import('../project/young-professionals/inner-page.vue' /* webpackChunkName: "" */))
const _0c427bfd = () => interopDefault(import('../project/leadership/index.vue' /* webpackChunkName: "" */))
const _578bd58b = () => interopDefault(import('../project/leadership/inner-page.vue' /* webpackChunkName: "" */))
const _1d1401b1 = () => interopDefault(import('../project/leadership-competency-development/index.vue' /* webpackChunkName: "" */))
const _490dc057 = () => interopDefault(import('../project/leadership-competency-development/inner-page.vue' /* webpackChunkName: "" */))
const _0807ab88 = () => interopDefault(import('../project/operational-efficiency/index.vue' /* webpackChunkName: "" */))
const _ab9f92a8 = () => interopDefault(import('../project/operational-efficiency/inner-page.vue' /* webpackChunkName: "" */))
const _7cbc25c4 = () => interopDefault(import('../project/marketing/index.vue' /* webpackChunkName: "" */))
const _01483024 = () => interopDefault(import('../project/marketing/inner-page.vue' /* webpackChunkName: "" */))
const _09953f38 = () => interopDefault(import('../project/finance/index.vue' /* webpackChunkName: "" */))
const _0328df30 = () => interopDefault(import('../project/finance/inner-page.vue' /* webpackChunkName: "" */))
const _6b88bb10 = () => interopDefault(import('../project/research-and-education/index.vue' /* webpackChunkName: "" */))
const _173e83f0 = () => interopDefault(import('../project/research-and-education/inner-page.vue' /* webpackChunkName: "" */))
const _ff103fd6 = () => interopDefault(import('../project/communications/index.vue' /* webpackChunkName: "" */))
const _0a094073 = () => interopDefault(import('../project/communications/inner-page.vue' /* webpackChunkName: "" */))
const _b71a340a = () => interopDefault(import('../project/strategy-management/index.vue' /* webpackChunkName: "" */))
const _3ba3fa4d = () => interopDefault(import('../project/strategy-management/inner-page.vue' /* webpackChunkName: "" */))
const _558f95cf = () => interopDefault(import('../project/professional-competency-development/index.vue' /* webpackChunkName: "" */))
const _343fe20e = () => interopDefault(import('../project/professional-competency-development/inner-page.vue' /* webpackChunkName: "" */))
const _20405f5a = () => interopDefault(import('../project/school-student/index.vue' /* webpackChunkName: "" */))
const _3476844e = () => interopDefault(import('../project/school-student/inner-page.vue' /* webpackChunkName: "" */))
const _7bd1d570 = () => interopDefault(import('../project/personnel-management/index.vue' /* webpackChunkName: "" */))
const _88ab59c0 = () => interopDefault(import('../project/personnel-management/inner-page.vue' /* webpackChunkName: "" */))
const _4466ceff = () => interopDefault(import('../project/academic/index.vue' /* webpackChunkName: "" */))
const _3b8d13c9 = () => interopDefault(import('../project/academic/inner-page.vue' /* webpackChunkName: "" */))
const _7957426a = () => interopDefault(import('../project/academic-centres/index.vue' /* webpackChunkName: "" */))
const _def9e984 = () => interopDefault(import('../project/academic-centres/inner-page.vue' /* webpackChunkName: "" */))
const _f996bf32 = () => interopDefault(import('../project/unlocking-territories/index.vue' /* webpackChunkName: "" */))
const _e99c393e = () => interopDefault(import('../project/unlocking-territories/inner-page.vue' /* webpackChunkName: "" */))
const _96bf7840 = () => interopDefault(import('../project/budushee-industrij/index.vue' /* webpackChunkName: "" */))
const _790e8388 = () => interopDefault(import('../project/budushee-industrij/inner-page.vue' /* webpackChunkName: "" */))
const _674deb42 = () => interopDefault(import('../project/voting/index.vue' /* webpackChunkName: "" */))
const _2a0dc0c0 = () => interopDefault(import('../project/welcome/index.vue' /* webpackChunkName: "" */))
const _76ec12a8 = () => interopDefault(import('../project/welcome/inner-page.vue' /* webpackChunkName: "" */))
const _1bb6ea08 = () => interopDefault(import('../project/derzhis-krepche/index.vue' /* webpackChunkName: "" */))
const _51280b37 = () => interopDefault(import('../project/istorii-o-budushem/index.vue' /* webpackChunkName: "" */))
const _03ced887 = () => interopDefault(import('../project/create-your-impact/index.vue' /* webpackChunkName: "" */))
const _20d5bf41 = () => interopDefault(import('../project/create-your-impact/inner-page.vue' /* webpackChunkName: "" */))
const _0382e176 = () => interopDefault(import('../project/alumni/index.vue' /* webpackChunkName: "" */))
const _103cf3b2 = () => interopDefault(import('../project/alumni/inner-page.vue' /* webpackChunkName: "" */))
const _e3e7d360 = () => interopDefault(import('../project/alumni-old/index.vue' /* webpackChunkName: "" */))
const _395691d0 = () => interopDefault(import('../project/alumni-old/inner-page.vue' /* webpackChunkName: "" */))
const _3648f7ef = () => interopDefault(import('../project/unsubscribe/index.vue' /* webpackChunkName: "" */))
const _5c5edf0e = () => interopDefault(import('../project/loyalty-system/index.vue' /* webpackChunkName: "" */))
const _749095cc = () => interopDefault(import('../project/loyalty-system/inner-page.vue' /* webpackChunkName: "" */))
const _3b7cf886 = () => interopDefault(import('../project/loyalty-system/ok-success.vue' /* webpackChunkName: "" */))
const _a4c357d2 = () => interopDefault(import('../project/campus/index.vue' /* webpackChunkName: "" */))
const _44ae5d6f = () => interopDefault(import('../project/campus/inner-page-infrastructure.vue' /* webpackChunkName: "" */))
const _011ff71f = () => interopDefault(import('../project/campus/inner-page-contacts.vue' /* webpackChunkName: "" */))
const _1a34fdb1 = () => interopDefault(import('../project/campus/inner-page.vue' /* webpackChunkName: "" */))
const _3c2ddc04 = () => interopDefault(import('../project/center/success.vue' /* webpackChunkName: "" */))
const _be124fe6 = () => interopDefault(import('../project/center/index.vue' /* webpackChunkName: "" */))
const _a702f90a = () => interopDefault(import('../project/center/inner-page.vue' /* webpackChunkName: "" */))
const _1cad207e = () => interopDefault(import('../project/walk/index.vue' /* webpackChunkName: "" */))
const _4d5b8db0 = () => interopDefault(import('../project/open-programs/index.vue' /* webpackChunkName: "" */))
const _11e8dbb8 = () => interopDefault(import('../project/open-programs/inner-page.vue' /* webpackChunkName: "" */))
const _0cafc31c = () => interopDefault(import('../project/partners-programme/index.vue' /* webpackChunkName: "" */))
const _3a027436 = () => interopDefault(import('../project/partners-programme/inner-page.vue' /* webpackChunkName: "" */))
const _07dfa933 = () => interopDefault(import('../project/user-confirm.vue' /* webpackChunkName: "" */))
const _32d887b0 = () => interopDefault(import('../project/event/inner-page.vue' /* webpackChunkName: "" */))
const _fbaeefd4 = () => interopDefault(import('../project/checkout/contract.vue' /* webpackChunkName: "" */))
const _1cddb7ec = () => interopDefault(import('../project/checkout/pay-method-invoice.vue' /* webpackChunkName: "" */))
const _d9050c22 = () => interopDefault(import('../project/checkout/pay-method-office.vue' /* webpackChunkName: "" */))
const _4840a0bc = () => interopDefault(import('../project/checkout/decline.vue' /* webpackChunkName: "" */))
const _fe42c74e = () => interopDefault(import('../project/checkout/invoice.vue' /* webpackChunkName: "" */))
const _15f74704 = () => interopDefault(import('../project/checkout/index.vue' /* webpackChunkName: "" */))
const _65d6d2ce = () => interopDefault(import('../project/vacancy/index.vue' /* webpackChunkName: "" */))
const _0cae9106 = () => interopDefault(import('../project/vacancy/show.vue' /* webpackChunkName: "" */))
const _0f0f06e8 = () => interopDefault(import('../project/navigation/index.vue' /* webpackChunkName: "" */))
const _615c89a4 = () => interopDefault(import('../project/event/preview.vue' /* webpackChunkName: "" */))
const _25c6781c = () => interopDefault(import('../project/event/test.vue' /* webpackChunkName: "" */))
const _683d9fdb = () => interopDefault(import('../project/event/detail.vue' /* webpackChunkName: "" */))
const _6cd48c9f = () => interopDefault(import('../project/event/ok-success/index.vue' /* webpackChunkName: "" */))
const _3ba25824 = () => interopDefault(import('../project/event/request-success/index.vue' /* webpackChunkName: "" */))
const _fe0478b6 = () => interopDefault(import('../project/event/payment-success/index.vue' /* webpackChunkName: "" */))
const _5f962d6a = () => interopDefault(import('../project/event/broadcast/index.vue' /* webpackChunkName: "" */))
const _5c4cf514 = () => interopDefault(import('../project/event/extended-registration/index.vue' /* webpackChunkName: "" */))
const _7b6c424a = () => interopDefault(import('../project/event/pdf/index.vue' /* webpackChunkName: "" */))
const _9dfd2320 = () => interopDefault(import('../project/summer-school/index.vue' /* webpackChunkName: "" */))
const _3e532da0 = () => interopDefault(import('../project/course/detail.vue' /* webpackChunkName: "" */))
const _041a073a = () => interopDefault(import('../project/lesson/detail.vue' /* webpackChunkName: "" */))
const _1bfed19b = () => interopDefault(import('../project/admissions/stages/detail.vue' /* webpackChunkName: "" */))
const _5bffc15e = () => interopDefault(import('../project/admissions/detail.vue' /* webpackChunkName: "" */))
const _f70db4c0 = () => interopDefault(import('../project/search/index.vue' /* webpackChunkName: "" */))
const _80fd778c = () => interopDefault(import('../project/article/expert-opinions/detail.vue' /* webpackChunkName: "" */))
const _e94bc2e2 = () => interopDefault(import('../project/article/news/detail.vue' /* webpackChunkName: "" */))
const _06a8c570 = () => interopDefault(import('../project/article/interviews/detail.vue' /* webpackChunkName: "" */))
const _566032ea = () => interopDefault(import('../project/article/cases/detail.vue' /* webpackChunkName: "" */))
const _0b7d9632 = () => interopDefault(import('../project/article/card/detail.vue' /* webpackChunkName: "" */))
const _0e8d1c28 = () => interopDefault(import('../project/article/unsorted/detail.vue' /* webpackChunkName: "" */))
const _51f2084d = () => interopDefault(import('../project/article/notes/detail.vue' /* webpackChunkName: "" */))
const _0baf5aca = () => interopDefault(import('../project/article/qna/detail.vue' /* webpackChunkName: "" */))
const _4b80b936 = () => interopDefault(import('../project/success-inner-page.vue' /* webpackChunkName: "" */))
const _931d5efa = () => interopDefault(import('../project/article/ok-success/index.vue' /* webpackChunkName: "" */))
const _1aea2b17 = () => interopDefault(import('../pages-base/detail.vue' /* webpackChunkName: "" */))
const _599514ec = () => interopDefault(import('../pages-base/inner-page.vue' /* webpackChunkName: "" */))
const _86f38842 = () => interopDefault(import('../pages-base/alumni-platform/welcome.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agreements/",
    component: _9bbf6454,
    pathToRegexpOptions: {"strict":true},
    name: "agreements"
  }, {
    path: "/booking-payment/",
    component: _8b8f0bca,
    pathToRegexpOptions: {"strict":true},
    name: "booking-payment"
  }, {
    path: "/cart/",
    component: _714253e6,
    pathToRegexpOptions: {"strict":true},
    name: "cart"
  }, {
    path: "/confirm-equis/",
    component: _f3a64528,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-equis"
  }, {
    path: "/constructor/",
    component: _5605bafa,
    pathToRegexpOptions: {"strict":true},
    name: "constructor"
  }, {
    path: "/convent-live/",
    component: _48fd90ac,
    pathToRegexpOptions: {"strict":true},
    name: "convent-live"
  }, {
    path: "/corporate-user/",
    component: _8d3e8640,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-user"
  }, {
    path: "/formulate-fail/",
    component: _78c7377e,
    pathToRegexpOptions: {"strict":true},
    name: "formulate-fail"
  }, {
    path: "/formulate-success/",
    component: _6049bed6,
    pathToRegexpOptions: {"strict":true},
    name: "formulate-success"
  }, {
    path: "/index-draft/",
    component: _625ebeec,
    pathToRegexpOptions: {"strict":true},
    name: "index-draft"
  }, {
    path: "/logout/",
    component: _7e7e87f4,
    pathToRegexpOptions: {"strict":true},
    name: "logout"
  }, {
    path: "/navigator-v2/",
    component: _2fd9b41f,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-v2"
  }, {
    path: "/navigator/",
    component: _ac1fefc0,
    pathToRegexpOptions: {"strict":true},
    name: "navigator"
  }, {
    path: "/ny2020-alumni/",
    component: _52ef17ca,
    pathToRegexpOptions: {"strict":true},
    name: "ny2020-alumni"
  }, {
    path: "/ny2024/",
    component: _359c4f35,
    pathToRegexpOptions: {"strict":true},
    name: "ny2024"
  }, {
    path: "/ok-success/",
    component: _6a1f03ff,
    pathToRegexpOptions: {"strict":true},
    name: "ok-success"
  }, {
    path: "/payment/",
    component: _03e9848f,
    pathToRegexpOptions: {"strict":true},
    name: "payment"
  }, {
    path: "/school-timeline/",
    component: _7ec73b80,
    pathToRegexpOptions: {"strict":true},
    name: "school-timeline"
  }, {
    path: "/tags/",
    component: _0f4058fa,
    pathToRegexpOptions: {"strict":true},
    name: "tags"
  }, {
    path: "/user-old/",
    component: _8d9b5e5c,
    pathToRegexpOptions: {"strict":true},
    name: "user-old"
  }, {
    path: "/user/",
    component: _3f42a610,
    pathToRegexpOptions: {"strict":true},
    name: "user"
  }, {
    path: "/6268fS4fxR5H3Qtwj7vh/hiding-page-test/",
    component: _1a660ed0,
    pathToRegexpOptions: {"strict":true},
    name: "6268fS4fxR5H3Qtwj7vh-hiding-page-test"
  }, {
    path: "/alumni-platform/directory/",
    component: _374830ee,
    pathToRegexpOptions: {"strict":true},
    name: "alumni-platform-directory"
  }, {
    path: "/article/detail.amp/",
    component: _8545746c,
    pathToRegexpOptions: {"strict":true},
    name: "article-detail.amp"
  }, {
    path: "/booking-payment/ok-success/",
    component: _329b3d99,
    pathToRegexpOptions: {"strict":true},
    name: "booking-payment-ok-success"
  }, {
    path: "/cart/cart/",
    component: _948b2c96,
    pathToRegexpOptions: {"strict":true},
    name: "cart-cart"
  }, {
    path: "/cart/register/",
    component: _7e9c5d38,
    pathToRegexpOptions: {"strict":true},
    name: "cart-register"
  }, {
    path: "/constructor/ok-success/",
    component: _dc10d39e,
    pathToRegexpOptions: {"strict":true},
    name: "constructor-ok-success"
  }, {
    path: "/corporate-user/profile/",
    component: _44c5d692,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "payment-success/",
      component: _b0637938,
      pathToRegexpOptions: {"strict":true},
      name: "corporate-user-profile-payment-success"
    }, {
      path: "settings/",
      component: _d57930aa,
      pathToRegexpOptions: {"strict":true},
      children: [{
        path: "account/",
        component: _ac2ea66e,
        pathToRegexpOptions: {"strict":true},
        name: "corporate-user-profile-settings-account",
        children: [{
          path: "Avatar/",
          component: _55b029af,
          pathToRegexpOptions: {"strict":true},
          name: "corporate-user-profile-settings-account-Avatar"
        }, {
          path: "Password/",
          component: _416b1a71,
          pathToRegexpOptions: {"strict":true},
          name: "corporate-user-profile-settings-account-Password"
        }]
      }, {
        path: "additionalInformation/",
        component: _28e453be,
        pathToRegexpOptions: {"strict":true},
        name: "corporate-user-profile-settings-additionalInformation"
      }, {
        path: "",
        component: _627041ee,
        pathToRegexpOptions: {"strict":true},
        name: "corporate-user-profile-settings"
      }]
    }, {
      path: "",
      component: _54def5fa,
      pathToRegexpOptions: {"strict":true},
      name: "corporate-user-profile"
    }]
  }, {
    path: "/feedback/campus/",
    component: _07ddc02f,
    pathToRegexpOptions: {"strict":true},
    name: "feedback-campus"
  }, {
    path: "/feedback/ok-success/",
    component: _32558fe5,
    pathToRegexpOptions: {"strict":true},
    name: "feedback-ok-success"
  }, {
    path: "/navigator/about-pogrammes/",
    component: _31ed8953,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-about-pogrammes"
  }, {
    path: "/navigator/content/",
    component: _74855a72,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-content"
  }, {
    path: "/navigator/events/",
    component: _b43f9f8a,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-events"
  }, {
    path: "/navigator/links/",
    component: _3a2ca1b0,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-links"
  }, {
    path: "/navigator/media/",
    component: _59a67632,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-media"
  }, {
    path: "/navigator/meta/",
    component: _4a45f5f0,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-meta"
  }, {
    path: "/navigator/professors-old/",
    component: _2a8c5940,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-professors-old"
  }, {
    path: "/navigator/programmes-with-price/",
    component: _5f933ef4,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-programmes-with-price"
  }, {
    path: "/navigator/programmes/",
    component: _519f180e,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-programmes"
  }, {
    path: "/navigator/vacancies/",
    component: _385459b2,
    pathToRegexpOptions: {"strict":true},
    name: "navigator-vacancies"
  }, {
    path: "/order/ok-success/",
    component: _31c359dd,
    pathToRegexpOptions: {"strict":true},
    name: "order-ok-success"
  }, {
    path: "/user-old/autologin/",
    component: _1d9b971a,
    pathToRegexpOptions: {"strict":true},
    name: "user-old-autologin"
  }, {
    path: "/user-old/profile-v1/",
    component: _3bce0262,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "applications/",
      component: _1ff55593,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-v1-applications"
    }, {
      path: "courses/",
      component: _514ebbf8,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-v1-courses"
    }, {
      path: "documents/",
      component: _fe2cbe10,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-v1-documents"
    }, {
      path: "edit/",
      component: _9537aa0c,
      pathToRegexpOptions: {"strict":true},
      children: [{
        path: "avatar/",
        component: _8d9ab384,
        pathToRegexpOptions: {"strict":true},
        name: "user-old-profile-v1-edit-avatar"
      }, {
        path: "password/",
        component: _637ea180,
        pathToRegexpOptions: {"strict":true},
        name: "user-old-profile-v1-edit-password"
      }, {
        path: "",
        component: _c681e406,
        pathToRegexpOptions: {"strict":true},
        name: "user-old-profile-v1-edit"
      }]
    }, {
      path: "library/",
      component: _30df837b,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-v1-library"
    }, {
      path: "orders/",
      component: _33047756,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-v1-orders"
    }, {
      path: "tests/",
      component: _23aed3e1,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-v1-tests"
    }, {
      path: "",
      component: _38d8ee12,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-v1"
    }]
  }, {
    path: "/user-old/profile/",
    component: _2f597429,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "admissions/",
      component: _cc74da40,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-admissions"
    }, {
      path: "applications/",
      component: _1fd3e279,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-applications"
    }, {
      path: "corporate/",
      component: _3f8e7602,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-corporate"
    }, {
      path: "courses/",
      component: _754d915c,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-courses"
    }, {
      path: "library/",
      component: _b62c0256,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-library"
    }, {
      path: "orders/",
      component: _c02d148a,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-orders"
    }, {
      path: "payment-success/",
      component: _12dd0254,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-payment-success"
    }, {
      path: "progress/",
      component: _e8bb8cfa,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-progress"
    }, {
      path: "settings/",
      component: _fe936f0e,
      pathToRegexpOptions: {"strict":true},
      children: [{
        path: "account/",
        component: _338cdf97,
        pathToRegexpOptions: {"strict":true},
        name: "user-old-profile-settings-account",
        children: [{
          path: "Avatar/",
          component: _04fc59be,
          pathToRegexpOptions: {"strict":true},
          name: "user-old-profile-settings-account-Avatar"
        }, {
          path: "Password/",
          component: _3b782f63,
          pathToRegexpOptions: {"strict":true},
          name: "user-old-profile-settings-account-Password"
        }]
      }, {
        path: "additionalInformation/",
        component: _20fbef22,
        pathToRegexpOptions: {"strict":true},
        name: "user-old-profile-settings-additionalInformation"
      }, {
        path: "agreements/",
        component: _cbfc69a2,
        pathToRegexpOptions: {"strict":true},
        name: "user-old-profile-settings-agreements"
      }, {
        path: "documentation/",
        component: _f1ef37b8,
        pathToRegexpOptions: {"strict":true},
        children: [{
          path: "",
          component: _d74b80b2,
          pathToRegexpOptions: {"strict":true},
          name: "user-old-profile-settings-documentation"
        }]
      }, {
        path: "",
        component: _757ed03c,
        pathToRegexpOptions: {"strict":true},
        name: "user-old-profile-settings"
      }]
    }, {
      path: "loyalty/about/",
      component: _b7f936c4,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-loyalty-about"
    }, {
      path: "loyalty/card/",
      component: _7581f4e2,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-loyalty-card"
    }, {
      path: "loyalty/faq/",
      component: _e7b86c32,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-loyalty-faq"
    }, {
      path: "loyalty/gift/",
      component: _0f4638a2,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-loyalty-gift"
    }, {
      path: "loyalty/history/",
      component: _74762036,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-loyalty-history"
    }, {
      path: "loyalty/report/",
      component: _063a601a,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile-loyalty-report"
    }, {
      path: "",
      component: _681e77ec,
      pathToRegexpOptions: {"strict":true},
      name: "user-old-profile"
    }]
  }, {
    path: "/user/autologin/",
    component: _3d5d1f80,
    pathToRegexpOptions: {"strict":true},
    name: "user-autologin"
  }, {
    path: "/user/directory/",
    component: _24056613,
    pathToRegexpOptions: {"strict":true},
    name: "user-directory"
  }, {
    path: "/user/profile-v3/",
    component: _d58b0d2a,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "admissions/",
      component: _3d67df44,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-admissions"
    }, {
      path: "alumni-space/",
      component: _4072a66a,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-alumni-space"
    }, {
      path: "courses/",
      component: _4a09da94,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-courses"
    }, {
      path: "dashboard/",
      component: _a3c45b20,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-dashboard"
    }, {
      path: "favorite/",
      component: _dcd89320,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-favorite"
    }, {
      path: "index_old/",
      component: _3e987216,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-index_old"
    }, {
      path: "invoices/",
      component: _3b225aba,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-invoices"
    }, {
      path: "loyalty/",
      component: _3f80e925,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty"
    }, {
      path: "media/",
      component: _190d1c80,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-media"
    }, {
      path: "orders/",
      component: _83d6cc08,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-orders"
    }, {
      path: "payment-success/",
      component: _d0d87fd0,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-payment-success"
    }, {
      path: "programs-old/",
      component: _c3b4d3c6,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-old"
    }, {
      path: "programs/",
      component: _47a9e606,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs"
    }, {
      path: "registrations/",
      component: _31097c94,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-registrations"
    }, {
      path: "loyalty/about/",
      component: _1fc90e60,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-about"
    }, {
      path: "loyalty/badges/",
      component: _6fa6f726,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-badges"
    }, {
      path: "loyalty/card/",
      component: _451c1b8d,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-card"
    }, {
      path: "loyalty/donate-points/",
      component: _5f9257d4,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-donate-points"
    }, {
      path: "loyalty/faq/",
      component: _356cfc29,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-faq"
    }, {
      path: "loyalty/gift/",
      component: _7839f9ad,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-gift"
    }, {
      path: "loyalty/history/",
      component: _66c73127,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-history"
    }, {
      path: "loyalty/loyalty-history/",
      component: _16375cba,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-loyalty-history"
    }, {
      path: "loyalty/partners-privileges/",
      component: _511e5a57,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-partners-privileges"
    }, {
      path: "loyalty/privileges/",
      component: _0db6079f,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-privileges"
    }, {
      path: "loyalty/referral-promo-code/",
      component: _f49636a4,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-referral-promo-code"
    }, {
      path: "loyalty/report/",
      component: _0c5b431e,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-report"
    }, {
      path: "loyalty/special-promotions/",
      component: _c1c890fe,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-loyalty-special-promotions"
    }, {
      path: "",
      component: _43c303ae,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3"
    }, {
      path: "orders/:id/",
      component: _4e8e2938,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-orders-id"
    }, {
      path: "programs/:program/",
      component: _22a92604,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program"
    }, {
      path: "programs/:program/about-info/",
      component: _4d8f4b2c,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-about-info"
    }, {
      path: "programs/:program/about/",
      component: _02f14b3f,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-about"
    }, {
      path: "programs/:program/admission-application/",
      component: _3b4c05be,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-admission-application"
    }, {
      path: "programs/:program/agreements/",
      component: _0f645b32,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-agreements"
    }, {
      path: "programs/:program/class-profile/",
      component: _1cef5086,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-class-profile"
    }, {
      path: "programs/:program/class-schedule/",
      component: _7fed298a,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-class-schedule"
    }, {
      path: "programs/:program/contracts/",
      component: _cbe69b5a,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-contracts"
    }, {
      path: "programs/:program/education-documents/",
      component: _7cb9ea05,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-education-documents"
    }, {
      path: "programs/:program/elective/",
      component: _d47c6db2,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-elective"
    }, {
      path: "programs/:program/organizational/",
      component: _5a9dd448,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-organizational"
    }, {
      path: "programs/:program/schedule/",
      component: _93098056,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-v3-programs-program-schedule"
    }]
  }, {
    path: "/user/profile/",
    component: _3cdf5ecf,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "admissions/",
      component: _950ab70c,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-admissions"
    }, {
      path: "alumni-space/",
      component: _62a0a9e7,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-alumni-space"
    }, {
      path: "courses/",
      component: _2fb51bf8,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-courses"
    }, {
      path: "favorite/",
      component: _6150a18c,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-favorite"
    }, {
      path: "index_old/",
      component: _6678ea7a,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-index_old"
    }, {
      path: "invoices/",
      component: _0adf45d6,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-invoices"
    }, {
      path: "loyalty-b2b/",
      component: _4944514e,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-b2b"
    }, {
      path: "loyalty/",
      component: _17e68f89,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty"
    }, {
      path: "media/",
      component: _25e487b8,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-media"
    }, {
      path: "orders/",
      component: _40ee9718,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-orders"
    }, {
      path: "payment-success/",
      component: _25ad4508,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-payment-success"
    }, {
      path: "programs-old/",
      component: _20ff9339,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-old"
    }, {
      path: "programs/",
      component: _7bf90c22,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs"
    }, {
      path: "registrations/",
      component: _803e2fcc,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-registrations"
    }, {
      path: "loyalty-b2b/about/",
      component: _298c7689,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-b2b-about"
    }, {
      path: "loyalty-b2b/card/",
      component: _2ca68a44,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-b2b-card"
    }, {
      path: "loyalty-b2b/earn-and-spent-points/",
      component: _079356a9,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-b2b-earn-and-spent-points"
    }, {
      path: "loyalty-b2b/faq/",
      component: _3be34adc,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-b2b-faq"
    }, {
      path: "loyalty-b2b/history/",
      component: _0d513310,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-b2b-history"
    }, {
      path: "loyalty-b2b/loyalty-history/",
      component: _060c47cc,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-b2b-loyalty-history"
    }, {
      path: "loyalty-b2b/report/",
      component: _287bfb68,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-b2b-report"
    }, {
      path: "loyalty/about/",
      component: _0fa29678,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-about"
    }, {
      path: "loyalty/badges/",
      component: _0708aaee,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-badges"
    }, {
      path: "loyalty/card/",
      component: _7013ceae,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-card"
    }, {
      path: "loyalty/create-group-donation/",
      component: _7e300d70,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-create-group-donation"
    }, {
      path: "loyalty/donate-points/",
      component: _4dad5a38,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-donate-points"
    }, {
      path: "loyalty/faq/",
      component: _31de28e6,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-faq"
    }, {
      path: "loyalty/gift/",
      component: _09d8126e,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-gift"
    }, {
      path: "loyalty/group-donation/",
      component: _9aeb7988,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-group-donation"
    }, {
      path: "loyalty/history/",
      component: _874662ea,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-history"
    }, {
      path: "loyalty/loyalty-history/",
      component: _0b98b007,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-loyalty-history"
    }, {
      path: "loyalty/my-group-donations/",
      component: _095a8e0b,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-my-group-donations"
    }, {
      path: "loyalty/partners-privileges/",
      component: _755f01bb,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-partners-privileges"
    }, {
      path: "loyalty/privileges/",
      component: _c8e6808a,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-privileges"
    }, {
      path: "loyalty/referral-promo-code/",
      component: _ac14e7dc,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-referral-promo-code"
    }, {
      path: "loyalty/report/",
      component: _2e21848d,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-report"
    }, {
      path: "loyalty/special-promotions/",
      component: _16bb3060,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-special-promotions"
    }, {
      path: "",
      component: _3d574e12,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile"
    }, {
      path: "loyalty/group-donation/:code?/",
      component: _97561794,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-group-donation-code"
    }, {
      path: "loyalty/special-promotions/:id?/",
      component: _0619cac8,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-loyalty-special-promotions-id"
    }, {
      path: "orders/:id/",
      component: _a6310100,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-orders-id"
    }, {
      path: "programs/:program/",
      component: _13e5c130,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program"
    }, {
      path: "programs/:program/about-info/",
      component: _9d38df70,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-about-info"
    }, {
      path: "programs/:program/about/",
      component: _535576ba,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-about"
    }, {
      path: "programs/:program/academic-plan/",
      component: _6cd61e45,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-academic-plan"
    }, {
      path: "programs/:program/admission-application/",
      component: _b80e91bc,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-admission-application"
    }, {
      path: "programs/:program/agreements/",
      component: _5c221783,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-agreements"
    }, {
      path: "programs/:program/class-profile/",
      component: _5805a5ea,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-class-profile"
    }, {
      path: "programs/:program/class-schedule/",
      component: _b0bcfeb4,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-class-schedule"
    }, {
      path: "programs/:program/contracts/",
      component: _83654c92,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-contracts"
    }, {
      path: "programs/:program/documents/",
      component: _3b59374e,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-documents"
    }, {
      path: "programs/:program/education-documents/",
      component: _aa28b72e,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-education-documents"
    }, {
      path: "programs/:program/elective-bachelor/",
      component: _3d06a4b0,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-elective-bachelor"
    }, {
      path: "programs/:program/elective/",
      component: _54dca543,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-elective"
    }, {
      path: "programs/:program/grades/",
      component: _aa691a54,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-grades"
    }, {
      path: "programs/:program/organizational/",
      component: _0b352610,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-organizational"
    }, {
      path: "programs/:program/payments/",
      component: _13bd5d87,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-payments"
    }, {
      path: "programs/:program/schedule/",
      component: _75961bf1,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-schedule"
    }, {
      path: "programs/:program/statements/",
      component: _de07d184,
      pathToRegexpOptions: {"strict":true},
      name: "user-profile-programs-program-statements"
    }]
  }, {
    path: "/alumni-platform/directory/welcome/",
    component: _2ba7c659,
    pathToRegexpOptions: {"strict":true},
    name: "alumni-platform-directory-welcome"
  }, {
    path: "/cart/cart_content/emptyCart/",
    component: _994ab426,
    pathToRegexpOptions: {"strict":true},
    name: "cart-cart_content-emptyCart"
  }, {
    path: "/cart/cart_content/fullCart/",
    component: _5d7fd09f,
    pathToRegexpOptions: {"strict":true},
    name: "cart-cart_content-fullCart"
  }, {
    path: "/sp/dev/course_blocks/",
    component: _69477b68,
    pathToRegexpOptions: {"strict":true},
    name: "sp-dev-course_blocks"
  }, {
    path: "/sp/dev/lk_learn/",
    component: _2745bcb6,
    pathToRegexpOptions: {"strict":true},
    name: "sp-dev-lk_learn"
  }, {
    path: "/sp/dev/tst1/",
    component: _746499a4,
    pathToRegexpOptions: {"strict":true},
    name: "sp-dev-tst1"
  }, {
    path: "/",
    component: _4874ab58,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/module-tests-v2/add/:stream?/",
    component: _8e7f60de,
    pathToRegexpOptions: {"strict":true},
    name: "module-tests-v2-add-stream"
  }, {
    path: "/activate/:token/",
    component: _92b8dbc2,
    pathToRegexpOptions: {"strict":true},
    name: "activate-token"
  }, {
    path: "/donations/:hash/",
    component: _5d8b4ec8,
    pathToRegexpOptions: {"strict":true},
    name: "donations-hash"
  }, {
    path: "/event-qr/:hash?/",
    component: _aa94aa88,
    pathToRegexpOptions: {"strict":true},
    name: "event-qr-hash"
  }, {
    path: "/formulate-success/:scenario_id?/",
    component: _721d4997,
    pathToRegexpOptions: {"strict":true},
    name: "formulate-success-scenario_id"
  }, {
    path: "/module-tests-v2/:id?/",
    component: _333d68cc,
    pathToRegexpOptions: {"strict":true},
    name: "module-tests-v2-id"
  }, {
    path: "/module-tests/:stream?/",
    component: _720cf8fa,
    pathToRegexpOptions: {"strict":true},
    name: "module-tests-stream"
  }, {
    path: "/order/:hash?/",
    component: _dcd5e21c,
    pathToRegexpOptions: {"strict":true},
    name: "order-hash"
  }, {
    path: "/stream/:stream?/",
    component: _8ab2b30c,
    pathToRegexpOptions: {"strict":true},
    name: "stream-stream"
  }, {
    path: "/donations/:hash?/qr/",
    component: _5c03289b,
    pathToRegexpOptions: {"strict":true},
    name: "donations-hash-qr"
  }, {
    path: "/donations/:hash?/result/",
    component: _07e08397,
    pathToRegexpOptions: {"strict":true},
    name: "donations-hash-result"
  }, {
    path: "/invoices/:hash?/qr/",
    component: _0228a312,
    pathToRegexpOptions: {"strict":true},
    name: "invoices-hash-qr"
  }, {
    path: "/:slug/success/",
    component: _79f5581e,
    pathToRegexpOptions: {"strict":true},
    name: "ok"
  }, {
    path: "/sp/anti-fragility/",
    component: _d4ef9674,
    pathToRegexpOptions: {"strict":true},
    name: "anti-fragility"
  }, {
    path: "/sp/anti-fragility/content/",
    component: _70c88eb0,
    pathToRegexpOptions: {"strict":true},
    name: "anti-fragility-content"
  }, {
    path: "/professors/",
    component: _c67f7322,
    pathToRegexpOptions: {"strict":true},
    name: "professors"
  }, {
    path: "/en/professors/",
    component: _c67f7322,
    pathToRegexpOptions: {"strict":true},
    name: "professors___en"
  }, {
    path: "/en/",
    component: _4874ab58,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/company-auth/",
    component: _4874ab58,
    pathToRegexpOptions: {"strict":true},
    name: "company-auth"
  }, {
    path: "/secret-santa/",
    component: _890245b0,
    pathToRegexpOptions: {"strict":true},
    name: "secret-santa"
  }, {
    path: "/en/secret-santa/",
    component: _890245b0,
    pathToRegexpOptions: {"strict":true},
    name: "secret-santa___en"
  }, {
    path: "/en/programmes/",
    component: _4882e38f,
    pathToRegexpOptions: {"strict":true},
    name: "programmes___en"
  }, {
    path: "/professors/:slug/cv-old/",
    component: _58d6fc33,
    pathToRegexpOptions: {"strict":true},
    name: "professor-cv-old"
  }, {
    path: "/en/professors/:slug/cv-old/",
    component: _58d6fc33,
    pathToRegexpOptions: {"strict":true},
    name: "professor-cv-old___en"
  }, {
    path: "/professors/:slug/:innerSlug?/",
    component: _5ac374b7,
    pathToRegexpOptions: {"strict":true},
    name: "professor-detail"
  }, {
    path: "/en/professors/:slug/:innerSlug?/",
    component: _5ac374b7,
    pathToRegexpOptions: {"strict":true},
    name: "professor-detail___en"
  }, {
    path: "/management/:slug/cv-old/",
    component: _500c4b18,
    pathToRegexpOptions: {"strict":true},
    name: "management-cv-old"
  }, {
    path: "/en/management/:slug/cv-old/",
    component: _500c4b18,
    pathToRegexpOptions: {"strict":true},
    name: "management-cv-old___en"
  }, {
    path: "/management/:slug/:innerSlug?/",
    component: _4c335a10,
    pathToRegexpOptions: {"strict":true},
    name: "management-detail"
  }, {
    path: "/en/management/:slug/:innerSlug?/",
    component: _4c335a10,
    pathToRegexpOptions: {"strict":true},
    name: "management-detail___en"
  }, {
    path: "/partners/:slug/",
    component: _5efc9b60,
    pathToRegexpOptions: {"strict":true},
    name: "partners-detail"
  }, {
    path: "/media/",
    component: _17c7e932,
    pathToRegexpOptions: {"strict":true},
    name: "article-section"
  }, {
    path: "/partner-event/:id/",
    component: _4bc8e9b7,
    pathToRegexpOptions: {"strict":true},
    name: "partner-event-detail"
  }, {
    path: "/partner-material/:id/",
    component: _488b2a98,
    pathToRegexpOptions: {"strict":true},
    name: "partner-material-detail"
  }, {
    path: "/degree/",
    component: _2e55dc78,
    pathToRegexpOptions: {"strict":true},
    name: "degree"
  }, {
    path: "/ips/",
    component: _d062baec,
    pathToRegexpOptions: {"strict":true},
    name: "ips"
  }, {
    path: "/hddm/",
    component: _6d6fed7d,
    pathToRegexpOptions: {"strict":true},
    name: "hddm"
  }, {
    path: "/en/hddm/",
    component: _6d6fed7d,
    pathToRegexpOptions: {"strict":true},
    name: "hddm___en"
  }, {
    path: "/hddm/:slug/",
    component: _5889540b,
    pathToRegexpOptions: {"strict":true},
    name: "hddm-inner-page"
  }, {
    path: "/en/hddm/:slug/",
    component: _5889540b,
    pathToRegexpOptions: {"strict":true},
    name: "hddm-inner-page___en"
  }, {
    path: "/tinkoff-credit/",
    component: _76d4f741,
    pathToRegexpOptions: {"strict":true},
    name: "tinkoff-credit"
  }, {
    path: "/intrend/",
    component: _c0001554,
    pathToRegexpOptions: {"strict":true},
    name: "inTrend"
  }, {
    path: "/intrend/:slug/",
    component: _b79d685c,
    pathToRegexpOptions: {"strict":true},
    name: "inTrend-inner-page"
  }, {
    path: "/events/",
    component: _a8437290,
    pathToRegexpOptions: {"strict":true},
    name: "event-list"
  }, {
    path: "/donation/",
    component: _524db06c,
    pathToRegexpOptions: {"strict":true},
    name: "donation"
  }, {
    path: "/donation/:slug/",
    component: _c6c23dee,
    pathToRegexpOptions: {"strict":true},
    name: "donation-detail"
  }, {
    path: "/en/donation/",
    component: _524db06c,
    pathToRegexpOptions: {"strict":true},
    name: "donation___en"
  }, {
    path: "/en/donation/:slug/",
    component: _c6c23dee,
    pathToRegexpOptions: {"strict":true},
    name: "donation-detail___en"
  }, {
    path: "/researches/:slug/",
    component: _310b3760,
    pathToRegexpOptions: {"strict":true},
    name: "researches-detail"
  }, {
    path: "/en/researches/:slug/",
    component: _310b3760,
    pathToRegexpOptions: {"strict":true},
    name: "researches-detail___en"
  }, {
    path: "/longread/:slug/",
    component: _7fd752ee,
    pathToRegexpOptions: {"strict":true},
    name: "longread-detail"
  }, {
    path: "/en/longread/:slug/",
    component: _7fd752ee,
    pathToRegexpOptions: {"strict":true},
    name: "longread-detail___en"
  }, {
    path: "/success/",
    component: _2876667b,
    pathToRegexpOptions: {"strict":true},
    name: "success"
  }, {
    path: "/crec/",
    component: _46080245,
    pathToRegexpOptions: {"strict":true},
    name: "crec"
  }, {
    path: "/crec/governance-crec/:slug?/",
    component: _36d4987c,
    pathToRegexpOptions: {"strict":true},
    name: "governance"
  }, {
    path: "/crec/:slug/",
    component: _d5b2077a,
    pathToRegexpOptions: {"strict":true},
    name: "crec-inner-page"
  }, {
    path: "/about-school/",
    component: _15267e28,
    pathToRegexpOptions: {"strict":true},
    name: "about-school"
  }, {
    path: "/en/about-school/",
    component: _15267e28,
    pathToRegexpOptions: {"strict":true},
    name: "about-school___en"
  }, {
    path: "/about-school/sustainability/:slug?/",
    component: _4aee7580,
    pathToRegexpOptions: {"strict":true},
    name: "sustainability"
  }, {
    path: "/en/about-school/sustainability/:slug?/",
    component: _4aee7580,
    pathToRegexpOptions: {"strict":true},
    name: "sustainability___en"
  }, {
    path: "/about-school/:slug/",
    component: _3a024dfc,
    pathToRegexpOptions: {"strict":true},
    name: "about-school-inner-page"
  }, {
    path: "/en/about-school/:slug/",
    component: _3a024dfc,
    pathToRegexpOptions: {"strict":true},
    name: "about-school-inner-page___en"
  }, {
    path: "/artifact-space/",
    component: _1b4d8883,
    pathToRegexpOptions: {"strict":true},
    name: "artifact-space"
  }, {
    path: "/en/artifact-space/",
    component: _1b4d8883,
    pathToRegexpOptions: {"strict":true},
    name: "artifact-space___en"
  }, {
    path: "/artifact-space/:slug/",
    component: _5631acc5,
    pathToRegexpOptions: {"strict":true},
    name: "artifact-space-inner-page"
  }, {
    path: "/en/artifact-space/:slug/",
    component: _5631acc5,
    pathToRegexpOptions: {"strict":true},
    name: "artifact-space-inner-page___en"
  }, {
    path: "/convent2020/",
    component: _b4520d5e,
    pathToRegexpOptions: {"strict":true},
    name: "convent2020"
  }, {
    path: "/convent2020/:slug/",
    component: _77948cb7,
    pathToRegexpOptions: {"strict":true},
    name: "convent2020-inner-page"
  }, {
    path: "/corporate/",
    component: _29f23d7a,
    pathToRegexpOptions: {"strict":true},
    name: "corporate"
  }, {
    path: "/corporate/skolkovo-china-unit/",
    component: _66da27b2,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-china-unit"
  }, {
    path: "/corporate/:slug/",
    component: _379c67f6,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-inner-page"
  }, {
    path: "/en/corporate/",
    component: _29f23d7a,
    pathToRegexpOptions: {"strict":true},
    name: "corporate___en"
  }, {
    path: "/en/corporate/skolkovo-china-unit/",
    component: _66da27b2,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-china-unit___en"
  }, {
    path: "/en/corporate/:slug/",
    component: _379c67f6,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-inner-page___en"
  }, {
    path: "/corporate-old/",
    component: _10c1679d,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-old"
  }, {
    path: "/corporate-old/:slug/",
    component: _f4a1a42a,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-old-inner-page"
  }, {
    path: "/en/corporate-old/",
    component: _10c1679d,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-old___en"
  }, {
    path: "/en/corporate-old/:slug/",
    component: _f4a1a42a,
    pathToRegexpOptions: {"strict":true},
    name: "corporate-old-inner-page___en"
  }, {
    path: "/b2g-programmes/",
    component: _3a7b06f5,
    pathToRegexpOptions: {"strict":true},
    name: "b2g"
  }, {
    path: "/b2g-programmes/:slug/",
    component: _704b6593,
    pathToRegexpOptions: {"strict":true},
    name: "b2g-inner-page"
  }, {
    path: "/en/b2g-programmes/",
    component: _3a7b06f5,
    pathToRegexpOptions: {"strict":true},
    name: "b2g___en"
  }, {
    path: "/en/b2g-programmes/:slug/",
    component: _704b6593,
    pathToRegexpOptions: {"strict":true},
    name: "b2g-inner-page___en"
  }, {
    path: "/internships/",
    component: _21b3d603,
    pathToRegexpOptions: {"strict":true},
    name: "internships"
  }, {
    path: "/internships/:slug/",
    component: _c1c06176,
    pathToRegexpOptions: {"strict":true},
    name: "internships-inner-page"
  }, {
    path: "/en/internships/",
    component: _21b3d603,
    pathToRegexpOptions: {"strict":true},
    name: "internships___en"
  }, {
    path: "/en/internships/:slug/",
    component: _c1c06176,
    pathToRegexpOptions: {"strict":true},
    name: "internships-inner-page___en"
  }, {
    path: "/personal-development/",
    component: _9f358234,
    pathToRegexpOptions: {"strict":true},
    name: "personal-development"
  }, {
    path: "/personal-development/:slug/",
    component: _db29077c,
    pathToRegexpOptions: {"strict":true},
    name: "personal-development-inner-page"
  }, {
    path: "/en/personal-development/",
    component: _9f358234,
    pathToRegexpOptions: {"strict":true},
    name: "personal-development___en"
  }, {
    path: "/en/personal-development/:slug/",
    component: _db29077c,
    pathToRegexpOptions: {"strict":true},
    name: "personal-development-inner-page___en"
  }, {
    path: "/trend-award-2022/",
    component: _4415704b,
    pathToRegexpOptions: {"strict":true},
    name: "trend-award"
  }, {
    path: "/en/trend-award-2022/",
    component: _4415704b,
    pathToRegexpOptions: {"strict":true},
    name: "trend-award___en"
  }, {
    path: "/digital/",
    component: _c7c0b014,
    pathToRegexpOptions: {"strict":true},
    name: "digital"
  }, {
    path: "/digital/:slug/",
    component: _33183d32,
    pathToRegexpOptions: {"strict":true},
    name: "digital-inner-page"
  }, {
    path: "/en/digital/",
    component: _c7c0b014,
    pathToRegexpOptions: {"strict":true},
    name: "digital___en"
  }, {
    path: "/en/digital/:slug/",
    component: _33183d32,
    pathToRegexpOptions: {"strict":true},
    name: "digital-inner-page___en"
  }, {
    path: "/privacy/",
    component: _6977d166,
    pathToRegexpOptions: {"strict":true},
    name: "privacy"
  }, {
    path: "/privacy/:slug/",
    component: _9adf0c7c,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-inner-page"
  }, {
    path: "/en/privacy/",
    component: _6977d166,
    pathToRegexpOptions: {"strict":true},
    name: "privacy___en"
  }, {
    path: "/en/privacy/:slug/",
    component: _9adf0c7c,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-inner-page___en"
  }, {
    path: "/probono/",
    component: _6e260919,
    pathToRegexpOptions: {"strict":true},
    name: "probono"
  }, {
    path: "/probono/:slug/",
    component: _76a019ef,
    pathToRegexpOptions: {"strict":true},
    name: "probono-inner-page"
  }, {
    path: "/en/probono/",
    component: _6e260919,
    pathToRegexpOptions: {"strict":true},
    name: "probono___en"
  }, {
    path: "/en/probono/:slug/",
    component: _76a019ef,
    pathToRegexpOptions: {"strict":true},
    name: "probono-inner-page___en"
  }, {
    path: "/msb/",
    component: _f480644c,
    pathToRegexpOptions: {"strict":true},
    name: "msb"
  }, {
    path: "/msb/:slug/",
    component: _144fb464,
    pathToRegexpOptions: {"strict":true},
    name: "msb-inner-page"
  }, {
    path: "/en/msb/",
    component: _f480644c,
    pathToRegexpOptions: {"strict":true},
    name: "msb___en"
  }, {
    path: "/en/msb/:slug/",
    component: _144fb464,
    pathToRegexpOptions: {"strict":true},
    name: "msb-inner-page___en"
  }, {
    path: "/rabota-v-shkole-upravleniya-skolkovo/",
    component: _90ef0ce2,
    pathToRegexpOptions: {"strict":true},
    name: "rabota-v-shkole-upravleniya-skolkovo"
  }, {
    path: "/rabota-v-shkole-upravleniya-skolkovo/:slug/",
    component: _bac0f98e,
    pathToRegexpOptions: {"strict":true},
    name: "rabota-v-shkole-upravleniya-skolkovo-inner-page"
  }, {
    path: "/en/rabota-v-shkole-upravleniya-skolkovo/",
    component: _90ef0ce2,
    pathToRegexpOptions: {"strict":true},
    name: "rabota-v-shkole-upravleniya-skolkovo___en"
  }, {
    path: "/en/rabota-v-shkole-upravleniya-skolkovo/:slug/",
    component: _bac0f98e,
    pathToRegexpOptions: {"strict":true},
    name: "rabota-v-shkole-upravleniya-skolkovo-inner-page___en"
  }, {
    path: "/healthcare-programs/",
    component: _4f61456d,
    pathToRegexpOptions: {"strict":true},
    name: "healthcare-programs"
  }, {
    path: "/healthcare-programs/:slug/",
    component: _1461721b,
    pathToRegexpOptions: {"strict":true},
    name: "healthcare-programs-inner-page"
  }, {
    path: "/en/healthcare-programs/",
    component: _4f61456d,
    pathToRegexpOptions: {"strict":true},
    name: "healthcare-programs___en"
  }, {
    path: "/en/healthcare-programs/:slug/",
    component: _1461721b,
    pathToRegexpOptions: {"strict":true},
    name: "healthcare-programs-inner-page___en"
  }, {
    path: "/bachelor-programmes/",
    component: _6c7c3434,
    pathToRegexpOptions: {"strict":true},
    name: "bachelor-programmes"
  }, {
    path: "/bachelor-programmes/:slug/",
    component: _e700b098,
    pathToRegexpOptions: {"strict":true},
    name: "bachelor-programmes-inner-page"
  }, {
    path: "/en/bachelor-programmes/",
    component: _6c7c3434,
    pathToRegexpOptions: {"strict":true},
    name: "bachelor-programmes___en"
  }, {
    path: "/en/bachelor-programmes/:slug/",
    component: _e700b098,
    pathToRegexpOptions: {"strict":true},
    name: "bachelor-programmes-inner-page___en"
  }, {
    path: "/bachelors-programmes/",
    component: _80eb8cd2,
    pathToRegexpOptions: {"strict":true},
    name: "bachelors-programmes"
  }, {
    path: "/bachelors-programmes/:slug/",
    component: _235c8831,
    pathToRegexpOptions: {"strict":true},
    name: "bachelors-programmes-inner-page"
  }, {
    path: "/en/bachelors-programmes/",
    component: _80eb8cd2,
    pathToRegexpOptions: {"strict":true},
    name: "bachelors-programmes___en"
  }, {
    path: "/en/bachelors-programmes/:slug/",
    component: _235c8831,
    pathToRegexpOptions: {"strict":true},
    name: "bachelors-programmes-inner-page___en"
  }, {
    path: "/young-professionals/",
    component: _303b36d5,
    pathToRegexpOptions: {"strict":true},
    name: "young-professionals"
  }, {
    path: "/young-professionals/:slug/",
    component: _76e3bc9a,
    pathToRegexpOptions: {"strict":true},
    name: "young-professionals-inner-page"
  }, {
    path: "/en/young-professionals/",
    component: _303b36d5,
    pathToRegexpOptions: {"strict":true},
    name: "young-professionals___en"
  }, {
    path: "/en/young-professionals/:slug/",
    component: _76e3bc9a,
    pathToRegexpOptions: {"strict":true},
    name: "young-professionals-inner-page___en"
  }, {
    path: "/leadership-and-personal-transformation/",
    component: _0c427bfd,
    pathToRegexpOptions: {"strict":true},
    name: "leadership"
  }, {
    path: "/leadership-and-personal-transformation/:slug/",
    component: _578bd58b,
    pathToRegexpOptions: {"strict":true},
    name: "leadership-inner-page"
  }, {
    path: "/en/leadership-and-personal-transformation/",
    component: _0c427bfd,
    pathToRegexpOptions: {"strict":true},
    name: "leadership___en"
  }, {
    path: "/en/leadership-and-personal-transformation/:slug/",
    component: _578bd58b,
    pathToRegexpOptions: {"strict":true},
    name: "leadership-inner-page___en"
  }, {
    path: "/leadership-competency-development/",
    component: _1d1401b1,
    pathToRegexpOptions: {"strict":true},
    name: "leadership-competency-development"
  }, {
    path: "/leadership-competency-development/:slug/",
    component: _490dc057,
    pathToRegexpOptions: {"strict":true},
    name: "leadership-competency-development-inner-page"
  }, {
    path: "/en/leadership-competency-development/",
    component: _1d1401b1,
    pathToRegexpOptions: {"strict":true},
    name: "leadership-competency-development___en"
  }, {
    path: "/en/leadership-competency-development/:slug/",
    component: _490dc057,
    pathToRegexpOptions: {"strict":true},
    name: "leadership-competency-development-inner-page___en"
  }, {
    path: "/operational-efficiency/",
    component: _0807ab88,
    pathToRegexpOptions: {"strict":true},
    name: "operational-efficiency"
  }, {
    path: "/operational-efficiency/:slug/",
    component: _ab9f92a8,
    pathToRegexpOptions: {"strict":true},
    name: "operational-efficiency-inner-page"
  }, {
    path: "/en/operational-efficiency/",
    component: _0807ab88,
    pathToRegexpOptions: {"strict":true},
    name: "operational-efficiency___en"
  }, {
    path: "/en/operational-efficiency/:slug/",
    component: _ab9f92a8,
    pathToRegexpOptions: {"strict":true},
    name: "operational-efficiency-inner-page___en"
  }, {
    path: "/marketing/",
    component: _7cbc25c4,
    pathToRegexpOptions: {"strict":true},
    name: "marketing"
  }, {
    path: "/marketing/:slug/",
    component: _01483024,
    pathToRegexpOptions: {"strict":true},
    name: "marketing-inner-page"
  }, {
    path: "/en/marketing/",
    component: _7cbc25c4,
    pathToRegexpOptions: {"strict":true},
    name: "marketing___en"
  }, {
    path: "/en/marketing/:slug/",
    component: _01483024,
    pathToRegexpOptions: {"strict":true},
    name: "marketing-inner-page___en"
  }, {
    path: "/finance-programs/",
    component: _09953f38,
    pathToRegexpOptions: {"strict":true},
    name: "finance"
  }, {
    path: "/finance-programs/:slug/",
    component: _0328df30,
    pathToRegexpOptions: {"strict":true},
    name: "finance-inner-page"
  }, {
    path: "/en/finance-programs/",
    component: _09953f38,
    pathToRegexpOptions: {"strict":true},
    name: "finance___en"
  }, {
    path: "/en/finance-programs/:slug/",
    component: _0328df30,
    pathToRegexpOptions: {"strict":true},
    name: "finance-inner-page___en"
  }, {
    path: "/research-and-education/",
    component: _6b88bb10,
    pathToRegexpOptions: {"strict":true},
    name: "research-and-education"
  }, {
    path: "/research-and-education/:slug/",
    component: _173e83f0,
    pathToRegexpOptions: {"strict":true},
    name: "research-and-education-inner-page"
  }, {
    path: "/en/research-and-education/",
    component: _6b88bb10,
    pathToRegexpOptions: {"strict":true},
    name: "research-and-education___en"
  }, {
    path: "/en/research-and-education/:slug/",
    component: _173e83f0,
    pathToRegexpOptions: {"strict":true},
    name: "research-and-education-inner-page___en"
  }, {
    path: "/communications/",
    component: _ff103fd6,
    pathToRegexpOptions: {"strict":true},
    name: "communications"
  }, {
    path: "/communications/:slug/",
    component: _0a094073,
    pathToRegexpOptions: {"strict":true},
    name: "communications-inner-page"
  }, {
    path: "/en/communications/",
    component: _ff103fd6,
    pathToRegexpOptions: {"strict":true},
    name: "communications___en"
  }, {
    path: "/en/communications/:slug/",
    component: _0a094073,
    pathToRegexpOptions: {"strict":true},
    name: "communications-inner-page___en"
  }, {
    path: "/strategy-management/",
    component: _b71a340a,
    pathToRegexpOptions: {"strict":true},
    name: "strategy-management"
  }, {
    path: "/strategy-management/:slug/",
    component: _3ba3fa4d,
    pathToRegexpOptions: {"strict":true},
    name: "strategy-management-inner-page"
  }, {
    path: "/en/strategy-management/",
    component: _b71a340a,
    pathToRegexpOptions: {"strict":true},
    name: "strategy-management___en"
  }, {
    path: "/en/strategy-management/:slug/",
    component: _3ba3fa4d,
    pathToRegexpOptions: {"strict":true},
    name: "strategy-management-inner-page___en"
  }, {
    path: "/professional-competency-development/",
    component: _558f95cf,
    pathToRegexpOptions: {"strict":true},
    name: "professional-competency-development"
  }, {
    path: "/professional-competency-development/:slug/",
    component: _343fe20e,
    pathToRegexpOptions: {"strict":true},
    name: "professional-competency-development-inner-page"
  }, {
    path: "/en/professional-competency-development/",
    component: _558f95cf,
    pathToRegexpOptions: {"strict":true},
    name: "professional-competency-development___en"
  }, {
    path: "/en/professional-competency-development/:slug/",
    component: _343fe20e,
    pathToRegexpOptions: {"strict":true},
    name: "professional-competency-development-inner-page___en"
  }, {
    path: "/school-student/",
    component: _20405f5a,
    pathToRegexpOptions: {"strict":true},
    name: "school-student"
  }, {
    path: "/school-student/:slug/",
    component: _3476844e,
    pathToRegexpOptions: {"strict":true},
    name: "school-student-inner-page"
  }, {
    path: "/en/school-student/",
    component: _20405f5a,
    pathToRegexpOptions: {"strict":true},
    name: "school-student___en"
  }, {
    path: "/en/school-student/:slug/",
    component: _3476844e,
    pathToRegexpOptions: {"strict":true},
    name: "school-student-inner-page___en"
  }, {
    path: "/personnel-management/",
    component: _7bd1d570,
    pathToRegexpOptions: {"strict":true},
    name: "personnel-management"
  }, {
    path: "/personnel-management/:slug/",
    component: _88ab59c0,
    pathToRegexpOptions: {"strict":true},
    name: "personnel-management-inner-page"
  }, {
    path: "/en/personnel-management/",
    component: _7bd1d570,
    pathToRegexpOptions: {"strict":true},
    name: "personnel-management___en"
  }, {
    path: "/en/personnel-management/:slug/",
    component: _88ab59c0,
    pathToRegexpOptions: {"strict":true},
    name: "personnel-management-inner-page___en"
  }, {
    path: "/academic/",
    component: _4466ceff,
    pathToRegexpOptions: {"strict":true},
    name: "academic"
  }, {
    path: "/academic/:slug/",
    component: _3b8d13c9,
    pathToRegexpOptions: {"strict":true},
    name: "academic-inner-page"
  }, {
    path: "/en/academic/",
    component: _4466ceff,
    pathToRegexpOptions: {"strict":true},
    name: "academic___en"
  }, {
    path: "/en/academic/:slug/",
    component: _3b8d13c9,
    pathToRegexpOptions: {"strict":true},
    name: "academic-inner-page___en"
  }, {
    path: "/test-academic-centres/",
    component: _7957426a,
    pathToRegexpOptions: {"strict":true},
    name: "academic-centres"
  }, {
    path: "/test-academic-centres/:slug/",
    component: _def9e984,
    pathToRegexpOptions: {"strict":true},
    name: "academic-centres-inner-page"
  }, {
    path: "/en/test-academic-centres/",
    component: _7957426a,
    pathToRegexpOptions: {"strict":true},
    name: "academic-centres___en"
  }, {
    path: "/en/test-academic-centres/:slug/",
    component: _def9e984,
    pathToRegexpOptions: {"strict":true},
    name: "academic-centres-inner-page___en"
  }, {
    path: "/unlocking-territories/",
    component: _f996bf32,
    pathToRegexpOptions: {"strict":true},
    name: "unlocking-territories"
  }, {
    path: "/en/unlocking-territories/",
    component: _f996bf32,
    pathToRegexpOptions: {"strict":true},
    name: "unlocking-territories___en"
  }, {
    path: "/unlocking-territories/:slug/",
    component: _e99c393e,
    pathToRegexpOptions: {"strict":true},
    name: "unlocking-territories-inner-page"
  }, {
    path: "/en/unlocking-territories/:slug/",
    component: _e99c393e,
    pathToRegexpOptions: {"strict":true},
    name: "unlocking-territories-inner-page___en"
  }, {
    path: "/budushee-industrij/",
    component: _96bf7840,
    pathToRegexpOptions: {"strict":true},
    name: "budushee-industrij"
  }, {
    path: "/en/budushee-industrij/",
    component: _96bf7840,
    pathToRegexpOptions: {"strict":true},
    name: "budushee-industrij___en"
  }, {
    path: "/budushee-industrij/:slug/",
    component: _790e8388,
    pathToRegexpOptions: {"strict":true},
    name: "budushee-industrij-inner-page"
  }, {
    path: "/en/budushee-industrij/:slug/",
    component: _790e8388,
    pathToRegexpOptions: {"strict":true},
    name: "budushee-industrij-inner-page___en"
  }, {
    path: "/voting/",
    component: _674deb42,
    pathToRegexpOptions: {"strict":true},
    name: "voting"
  }, {
    path: "/en/voting/",
    component: _674deb42,
    pathToRegexpOptions: {"strict":true},
    name: "voting___en"
  }, {
    path: "/welcome/",
    component: _2a0dc0c0,
    pathToRegexpOptions: {"strict":true},
    name: "welcome"
  }, {
    path: "/en/welcome/",
    component: _2a0dc0c0,
    pathToRegexpOptions: {"strict":true},
    name: "welcome___en"
  }, {
    path: "/welcome/:slug/",
    component: _76ec12a8,
    pathToRegexpOptions: {"strict":true},
    name: "welcome-inner-page"
  }, {
    path: "/en/welcome/:slug/",
    component: _76ec12a8,
    pathToRegexpOptions: {"strict":true},
    name: "welcome-inner-page___en"
  }, {
    path: "/derzhis-krepche/",
    component: _1bb6ea08,
    pathToRegexpOptions: {"strict":true},
    name: "derzhis-krepche"
  }, {
    path: "/en/derzhis-krepche/",
    component: _1bb6ea08,
    pathToRegexpOptions: {"strict":true},
    name: "derzhis-krepche___en"
  }, {
    path: "/istorii-o-budushem/",
    component: _51280b37,
    pathToRegexpOptions: {"strict":true},
    name: "istorii-o-budushem"
  }, {
    path: "/en/istorii-o-budushem/",
    component: _51280b37,
    pathToRegexpOptions: {"strict":true},
    name: "istorii-o-budushem___en"
  }, {
    path: "/create-your-impact/",
    component: _03ced887,
    pathToRegexpOptions: {"strict":true},
    name: "create-your-impact"
  }, {
    path: "/create-your-impact/:slug/",
    component: _20d5bf41,
    pathToRegexpOptions: {"strict":true},
    name: "create-your-impact-inner-page"
  }, {
    path: "/en/create-your-impact/",
    component: _03ced887,
    pathToRegexpOptions: {"strict":true},
    name: "create-your-impact___en"
  }, {
    path: "/en/create-your-impact/:slug/",
    component: _20d5bf41,
    pathToRegexpOptions: {"strict":true},
    name: "create-your-impact-inner-page___en"
  }, {
    path: "/alumni/",
    component: _0382e176,
    pathToRegexpOptions: {"strict":true},
    name: "alumni"
  }, {
    path: "/en/alumni/",
    component: _0382e176,
    pathToRegexpOptions: {"strict":true},
    name: "alumni___en"
  }, {
    path: "/alumni/:slug/",
    component: _103cf3b2,
    pathToRegexpOptions: {"strict":true},
    name: "alumni-inner-page"
  }, {
    path: "/en/alumni/:slug/",
    component: _103cf3b2,
    pathToRegexpOptions: {"strict":true},
    name: "alumni-inner-page___en"
  }, {
    path: "/alumni-old/",
    component: _e3e7d360,
    pathToRegexpOptions: {"strict":true},
    name: "alumni-old"
  }, {
    path: "/en/alumni-old/",
    component: _e3e7d360,
    pathToRegexpOptions: {"strict":true},
    name: "alumni-old___en"
  }, {
    path: "/alumni-old/:slug/",
    component: _395691d0,
    pathToRegexpOptions: {"strict":true},
    name: "alumni-old-inner-page"
  }, {
    path: "/en/alumni-old/:slug/",
    component: _395691d0,
    pathToRegexpOptions: {"strict":true},
    name: "alumni-old-inner-page___en"
  }, {
    path: "/unsubscribe/",
    component: _3648f7ef,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe"
  }, {
    path: "/en/unsubscribe/",
    component: _3648f7ef,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___en"
  }, {
    path: "/loyalty-system/",
    component: _5c5edf0e,
    pathToRegexpOptions: {"strict":true},
    name: "loyalty-system"
  }, {
    path: "/en/loyalty-system/",
    component: _5c5edf0e,
    pathToRegexpOptions: {"strict":true},
    name: "loyalty-system___en"
  }, {
    path: "/loyalty-system/:slug/",
    component: _749095cc,
    pathToRegexpOptions: {"strict":true},
    name: "loyalty-system-inner-page"
  }, {
    path: "/en/loyalty-system/:slug/",
    component: _749095cc,
    pathToRegexpOptions: {"strict":true},
    name: "loyalty-system-inner-page___en"
  }, {
    path: "/loyalty-system/ok-success/",
    component: _3b7cf886,
    pathToRegexpOptions: {"strict":true},
    name: "loyalty-system-success"
  }, {
    path: "/campus-rental/",
    component: _a4c357d2,
    pathToRegexpOptions: {"strict":true},
    name: "campus"
  }, {
    path: "/en/campus-rental/",
    component: _a4c357d2,
    pathToRegexpOptions: {"strict":true},
    name: "campus___en"
  }, {
    path: "/campus-rental/infrastructure/",
    component: _44ae5d6f,
    pathToRegexpOptions: {"strict":true},
    name: "campus-inner-page-infra"
  }, {
    path: "/en/campus-rental/infrastructure/",
    component: _44ae5d6f,
    pathToRegexpOptions: {"strict":true},
    name: "campus-inner-page-infra___en"
  }, {
    path: "/campus-rental/contacts/",
    component: _011ff71f,
    pathToRegexpOptions: {"strict":true},
    name: "campus-inner-page-contacts"
  }, {
    path: "/en/campus-rental/contacts/",
    component: _011ff71f,
    pathToRegexpOptions: {"strict":true},
    name: "campus-inner-page-contacts___en"
  }, {
    path: "/campus-rental/:slug/",
    component: _1a34fdb1,
    pathToRegexpOptions: {"strict":true},
    name: "campus-inner-page"
  }, {
    path: "/en/campus-rental/:slug/",
    component: _1a34fdb1,
    pathToRegexpOptions: {"strict":true},
    name: "campus-inner-page___en"
  }, {
    path: "/centres/:slug/success/",
    component: _3c2ddc04,
    pathToRegexpOptions: {"strict":true},
    name: "center-success"
  }, {
    path: "/en/centres/:slug/",
    component: _be124fe6,
    pathToRegexpOptions: {"strict":true},
    name: "center___en"
  }, {
    path: "/en/centres/:parentslug/:slug/",
    component: _a702f90a,
    pathToRegexpOptions: {"strict":true},
    name: "center-inner-page___en"
  }, {
    path: "/centres/:slug/",
    component: _be124fe6,
    pathToRegexpOptions: {"strict":true},
    name: "center"
  }, {
    path: "/centres/:parentslug/:slug/",
    component: _a702f90a,
    pathToRegexpOptions: {"strict":true},
    name: "center-inner-page"
  }, {
    path: "/walk/",
    component: _1cad207e,
    pathToRegexpOptions: {"strict":true},
    name: "walk"
  }, {
    path: "/open-programs/",
    component: _4d5b8db0,
    pathToRegexpOptions: {"strict":true},
    name: "open-programs"
  }, {
    path: "/open-programs/:slug/",
    component: _11e8dbb8,
    pathToRegexpOptions: {"strict":true},
    name: "open-programs-inner-page"
  }, {
    path: "/partners-programme/",
    component: _0cafc31c,
    pathToRegexpOptions: {"strict":true},
    name: "partners-programme"
  }, {
    path: "/partners-programme/:slug/",
    component: _3a027436,
    pathToRegexpOptions: {"strict":true},
    name: "partners-programme-inner-page"
  }, {
    path: "/user-confirm/",
    component: _07dfa933,
    pathToRegexpOptions: {"strict":true},
    name: "user-confirm"
  }, {
    path: "/autologin/:data/",
    component: _3d5d1f80,
    pathToRegexpOptions: {"strict":true},
    name: "autologin"
  }, {
    path: "/sp/:issue/conference/:eventSlug/:innerPageSlug/",
    component: _32d887b0,
    pathToRegexpOptions: {"strict":true},
    name: "event-inner-page"
  }, {
    path: "/checkout/contract/",
    component: _fbaeefd4,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-contract"
  }, {
    path: "/checkout/pay-invoice/",
    component: _1cddb7ec,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-pay-invoice"
  }, {
    path: "/checkout/pay-office/",
    component: _d9050c22,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-pay-office"
  }, {
    path: "/checkout/decline/",
    component: _4840a0bc,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-contract-decline"
  }, {
    path: "/checkout/invoice/",
    component: _fe42c74e,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-contract-invoice"
  }, {
    path: "/checkout/",
    component: _15f74704,
    pathToRegexpOptions: {"strict":true},
    name: "checkout"
  }, {
    path: "/vacancy/",
    component: _65d6d2ce,
    pathToRegexpOptions: {"strict":true},
    name: "vacancy"
  }, {
    path: "/vacancy/:id/",
    component: _0cae9106,
    pathToRegexpOptions: {"strict":true},
    name: "vacancy-inner-page"
  }, {
    path: "/navigation/",
    component: _0f0f06e8,
    pathToRegexpOptions: {"strict":true},
    name: "navigation"
  }, {
    path: "/events-test/:slug/",
    component: _615c89a4,
    pathToRegexpOptions: {"strict":true},
    name: "event-detail-test"
  }, {
    path: "/test/:slug/",
    component: _25c6781c,
    pathToRegexpOptions: {"strict":true},
    name: "detail-test"
  }, {
    path: "/events-test/:eventSlug/:innerPageSlug/",
    component: _32d887b0,
    pathToRegexpOptions: {"strict":true},
    name: "event-inner-page-test"
  }, {
    path: "/programmes/:slug/",
    component: _683d9fdb,
    pathToRegexpOptions: {"strict":true},
    name: "programmes-detail"
  }, {
    path: "/programmes/:slug/ok-success/",
    component: _6cd48c9f,
    pathToRegexpOptions: {"strict":true},
    name: "programmes-ok-success"
  }, {
    path: "/programmes/:slug/request-success/",
    component: _3ba25824,
    pathToRegexpOptions: {"strict":true},
    name: "programmes-request-success"
  }, {
    path: "/programmes/:slug/payment-success/",
    component: _fe0478b6,
    pathToRegexpOptions: {"strict":true},
    name: "programmes-payment-success"
  }, {
    path: "/programmes/:slug/broadcast/",
    component: _5f962d6a,
    pathToRegexpOptions: {"strict":true},
    name: "programmes-broadcast"
  }, {
    path: "/programmes/:slug/extended-registration/",
    component: _5c4cf514,
    pathToRegexpOptions: {"strict":true},
    name: "programmes-extended-registration"
  }, {
    path: "/programmes/:slug/pdf/",
    component: _7b6c424a,
    pathToRegexpOptions: {"strict":true},
    name: "programmes-pdf"
  }, {
    path: "/programmes/:eventSlug/:innerPageSlug/",
    component: _32d887b0,
    pathToRegexpOptions: {"strict":true},
    name: "programmes-inner-page"
  }, {
    path: "/events/:slug/",
    component: _683d9fdb,
    pathToRegexpOptions: {"strict":true},
    name: "events-detail"
  }, {
    path: "/events/:slug/ok-success/",
    component: _6cd48c9f,
    pathToRegexpOptions: {"strict":true},
    name: "events-ok-success"
  }, {
    path: "/events/:slug/request-success/",
    component: _3ba25824,
    pathToRegexpOptions: {"strict":true},
    name: "events-request-success"
  }, {
    path: "/events/:slug/payment-success/",
    component: _fe0478b6,
    pathToRegexpOptions: {"strict":true},
    name: "events-payment-success"
  }, {
    path: "/events/:slug/broadcast/",
    component: _5f962d6a,
    pathToRegexpOptions: {"strict":true},
    name: "events-broadcast"
  }, {
    path: "/events/:slug/extended-registration/",
    component: _5c4cf514,
    pathToRegexpOptions: {"strict":true},
    name: "events-extended-registration"
  }, {
    path: "/events/:slug/pdf/",
    component: _7b6c424a,
    pathToRegexpOptions: {"strict":true},
    name: "events-pdf"
  }, {
    path: "/events/:eventSlug/:innerPageSlug/",
    component: _32d887b0,
    pathToRegexpOptions: {"strict":true},
    name: "events-inner-page"
  }, {
    path: "/videos-summer-school-2020/",
    component: _9dfd2320,
    pathToRegexpOptions: {"strict":true},
    name: "summer-school"
  }, {
    path: "/courses/:slug/",
    component: _3e532da0,
    pathToRegexpOptions: {"strict":true},
    name: "course-detail"
  }, {
    path: "/lessons/:slug/",
    component: _041a073a,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-detail"
  }, {
    path: "/admissions/stages/:slug/",
    component: _1bfed19b,
    pathToRegexpOptions: {"strict":true},
    name: "admission-stage-detail"
  }, {
    path: "/admissions/:slug/",
    component: _5bffc15e,
    pathToRegexpOptions: {"strict":true},
    name: "admission-detail"
  }, {
    path: "/en/admissions/stages/:slug/",
    component: _1bfed19b,
    pathToRegexpOptions: {"strict":true},
    name: "admission-stage-detail__en"
  }, {
    path: "/en/admissions/:slug/",
    component: _5bffc15e,
    pathToRegexpOptions: {"strict":true},
    name: "admission-detail__en"
  }, {
    path: "/search/",
    component: _f70db4c0,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/expert-opinions/:slug/",
    component: _80fd778c,
    pathToRegexpOptions: {"strict":true},
    name: "expert-opinions-detail"
  }, {
    path: "/en/expert-opinions/:slug/",
    component: _80fd778c,
    pathToRegexpOptions: {"strict":true},
    name: "expert-opinions-detail___en"
  }, {
    path: "/news/:slug/",
    component: _e94bc2e2,
    pathToRegexpOptions: {"strict":true},
    name: "news-detail"
  }, {
    path: "/en/news/:slug/",
    component: _e94bc2e2,
    pathToRegexpOptions: {"strict":true},
    name: "news-detail___en"
  }, {
    path: "/interviews/:slug/",
    component: _06a8c570,
    pathToRegexpOptions: {"strict":true},
    name: "interviews-detail"
  }, {
    path: "/en/interviews/:slug/",
    component: _06a8c570,
    pathToRegexpOptions: {"strict":true},
    name: "interviews-detail___en"
  }, {
    path: "/cases/:slug/",
    component: _566032ea,
    pathToRegexpOptions: {"strict":true},
    name: "cases-detail"
  }, {
    path: "/en/cases/:slug/",
    component: _566032ea,
    pathToRegexpOptions: {"strict":true},
    name: "cases-detail___en"
  }, {
    path: "/card/:slug/",
    component: _0b7d9632,
    pathToRegexpOptions: {"strict":true},
    name: "card-detail"
  }, {
    path: "/en/card/:slug/",
    component: _0b7d9632,
    pathToRegexpOptions: {"strict":true},
    name: "card-detail___en"
  }, {
    path: "/unsorted/:slug/",
    component: _0e8d1c28,
    pathToRegexpOptions: {"strict":true},
    name: "unsorted-detail"
  }, {
    path: "/en/unsorted/:slug/",
    component: _0e8d1c28,
    pathToRegexpOptions: {"strict":true},
    name: "unsorted-detail___en"
  }, {
    path: "/notes/:slug/",
    component: _51f2084d,
    pathToRegexpOptions: {"strict":true},
    name: "notes-detail"
  }, {
    path: "/en/notes/:slug/",
    component: _51f2084d,
    pathToRegexpOptions: {"strict":true},
    name: "notes-detail___en"
  }, {
    path: "/qna/:slug/",
    component: _0baf5aca,
    pathToRegexpOptions: {"strict":true},
    name: "qna-detail"
  }, {
    path: "/en/qna/:slug/",
    component: _0baf5aca,
    pathToRegexpOptions: {"strict":true},
    name: "qna-detail___en"
  }, {
    path: "/:parentslug/:slug/success/",
    component: _4b80b936,
    pathToRegexpOptions: {"strict":true},
    name: "ok-inner-page"
  }, {
    path: "/article/:slug/ok-success/",
    component: _931d5efa,
    pathToRegexpOptions: {"strict":true},
    name: "ArticleOkSuccess"
  }, {
    path: "/:slug/",
    component: _1aea2b17,
    pathToRegexpOptions: {"strict":true},
    name: "pages-base-detail"
  }, {
    path: "/:parentSlug/:slug/",
    component: _599514ec,
    pathToRegexpOptions: {"strict":true},
    name: "pages-base-inner-page"
  }, {
    path: "/:parentSlug/:slug/welcome/",
    component: _86f38842,
    pathToRegexpOptions: {"strict":true},
    name: "pages-base-alumni-welcome"
  }, {
    path: "/:mediaTypeSlug/:slug/amp",
    component: _8545746c,
    name: "media-detail-amp"
  }],

  parseQuery: function(q) {
      return require('qs').parse(q);
    },
  stringifyQuery: function(q) {
      const r = require('qs').stringify(q, {
        arrayFormat: 'brackets',
        encode: false
      });
      return r ? `?${r}` : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
